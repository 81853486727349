import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import praveetech from "../../assets/praveeTech_logo.png";
import {
  CardMedia,
  Drawer,
  Hidden,
  List,
  ListItemButton,
  makeStyles,
  useMediaQuery,
} from "@mui/material";

import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import { FcGoogle } from "react-icons/fc";
import { AiFillTwitterCircle } from "react-icons/ai";
import FacebookSvg from "../../assets/svg/NavBarSvg/FacebookSvg";
import { Stack, unstable_styleFunctionSx } from "@mui/system";
import LinkedInSvg from "../../assets/svg/NavBarSvg/LinkedInSvg";
import TwitterSvg from "../../assets/svg/NavBarSvg/TwitterSvg";

const pages = ["Home", "Services", "About", "Case Studies", "Contact"];

function ResponsiveAppBar({
  handleService,
  handleAbout,
  handleCaseStudy,
  handleContact,
  open,
  handleHome,
  handleDrawerOpen,
  handleCareerPage,
  setOpen,
}) {
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <Box pb={2}>
      <Box>
        <AppBar component="nav" sx={{ backgroundColor: "white" }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                display={"flex"}
                flexGrow={1}
                sx={{ justifyContent: "end" }}
                spacing={2}
                direction={"row"}
              >
                <Box pt={1}>
                  <img height={matches ? 50 : 35} src={praveetech} />
                </Box>

                <Stack
                  display={"flex"}
                  flexGrow={1}
                  sx={{ justifyContent: "end" }}
                  spacing={2}
                  direction={"row"}
                >
                  <Hidden mdDown>
                    {pages.map((page) => (
                      <Button
                        key={page}
                        sx={{
                          my: 2,
                          color: "black",
                          display: "block",
                          "&:hover": {
                            color: "#10A5C1",
                          },
                        }}
                        onClick={() => {
                          switch (page) {
                            case "Services": // Updated to "Services"
                              handleService();
                              break;
                            case "About":
                              handleAbout();
                              break;
                            case "Case Studies": // Updated to "Case Studies"
                              handleCaseStudy();
                              break;
                            case "Contact":
                              handleContact();
                              break;
                            case "Home":
                              handleHome();
                              break;
                            case "Career":
                              handleCareerPage();
                              break;

                            default:
                              break;
                          }
                        }}
                      >
                        {page}
                      </Button>
                    ))}
                  </Hidden>
                  {/* <Hidden >
                  <Box>
                    <Stack spacing={2} marginTop={1} direction={"row"}>
                      <FacebookSvg />
                      <LinkedInSvg />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          width="23.9748"
                          height="24"
                          rx="20.9874"
                          fill="white"
                        />
                        <FcGoogle size={23} />
                      </svg>
                      <TwitterSvg />
                    </Stack>
                  </Box>
                </Hidden> */}
                  <Hidden mdUp>
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      size="small"
                      edge="end"
                      onClick={handleDrawerOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                </Stack>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <Box>
        <Drawer
          PaperProps={{
            style: {
              backgroundColor: "white",
              borderBottomRightRadius: "10px",
              borderTopRightRadius: "10px",
            },
          }}
          anchor="left"
          open={open}
        >
          <Box padding={2}>
            <img src={praveetech} width={200} alt="praveetech" />
            <hr></hr>
            <Box display={"flex"} sx={{ float: "right", marginRight: 2 }}>
              <IconButton
                color="primary"
                aria-label="open drawer"
                size="small"
                edge="end"
                onClick={() => setOpen(!open)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {pages.map((page) => (
            <Button
              key={page}
              sx={{ my: 2, display: "block" }}
              onClick={() => {
                switch (page) {
                  case "Services":
                    handleService();
                    break;
                  case "About":
                    handleAbout();
                    break;
                  case "Case Studies":
                    handleCaseStudy();
                    break;
                  case "Contact":
                    handleContact();
                    break;
                  case "Home":
                    handleHome();
                    break;
                  default:
                    break;
                }
              }}
            >
              <Typography
                color="black"
                fontSize={{ xs: 12, sm: 16 }}
                variant="SmallTitle"
              >
                {page}
              </Typography>
            </Button>
          ))}
        </Drawer>
      </Box>
    </Box>
  );
}
export default ResponsiveAppBar;
