import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import {
  Agile_Methodology,
  At_Praveetech_our_approach,
  By_harnessing_the_power,
  Client_Centric_Approach,
  Committed_to_delivering,
  Cross_Functional_Teams,
  Data_Driven_Decision,
  In_the_fast_paced,
  Innovating_with_Purpose,
  Nimble_and_Adaptive,
  One_Product_at_a_Time,
  Our_Approach,
  Our_diverse_team,
  We_believe_in_the_power,
  Your_are_at_the,
} from "../constants/translation";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import AgilemethologyImg from "../assets/Img/OurApproach/Agilemethology.png";
import ClientApproachImg from "../assets/Img/OurApproach/ClientApproach.png";
import DataDriveImg from "../assets/Img/OurApproach/Datadriven.png";
import NimbleAdaptiveImg from "../assets/Img/OurApproach/NimbleAdaptive.png";
import OneproductImg from "../assets/Img/OurApproach/Oneproduct.png";
import CrossImg from "../assets/Img/OurApproach/CrossFunctional.png";

function SectionApproach() {
  const [ref2, isInView2] = useInView({ triggerOnce: true });

  const CardApproach = ({ img, text, headingText }) => {
    return (
      <Box>
        <Card
          sx={{
            width: "100%",
            backgroundColor: "white",
            height: 220,
          }}
        >
          <Container>
            <Stack pt={2} direction={"row"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 56 56"
                fill="none"
              >
                <circle
                  cx="28"
                  cy="28"
                  r="28"
                  fill="url(#paint0_linear_154_6231)"
                />
                <image width={40} height={40} x="7" y="6" href={img} />
                <defs>
                  <linearGradient
                    id="paint0_linear_154_6231"
                    x1="28"
                    y1="0"
                    x2="28"
                    y2="56"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D7F9FF" />
                    <stop offset="0.989583" stop-color="#00D8FF" />
                  </linearGradient>
                </defs>
              </svg>
              <Box position={"relative"} right={6} bottom={9}>
                <CardHeader
                  title={
                    <Typography pt={1} variant="SecondaryHeaderTitle">
                      {headingText}
                    </Typography>
                  }
                />
              </Box>
            </Stack>

            <Typography paragraph variant="cardSubTitle">
              {text}
            </Typography>
          </Container>
        </Card>
      </Box>
    );
  };

  return (
    <Box pt={8}>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={isInView2 ? { opacity: 1, x: 0 } : {}}
        transition={{ type: "spring", duration: 1.5 }}
        ref={ref2}
      >
        <Box pb={8} textAlign={"center"}>
          <Container>
            <Typography variant="subHeadingTitle">{Our_Approach}</Typography>
            <Typography variant="headingTitle">
              <br />
              {Innovating_with_Purpose}
            </Typography>
            <Typography pt={2} paragraph variant="contentTitle2">
              {At_Praveetech_our_approach}
            </Typography>
          </Container>
        </Box>
      </motion.div>

      <Box pb={8} className="approachImg" pt={8}>
        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 6, sm: 8, md: 12, lg: 12 }}
          >
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={AgilemethologyImg}
                text={We_believe_in_the_power}
                headingText={Agile_Methodology}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={ClientApproachImg}
                text={In_the_fast_paced}
                headingText={Client_Centric_Approach}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={NimbleAdaptiveImg}
                text={Your_are_at_the}
                headingText={Nimble_and_Adaptive}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={OneproductImg}
                text={Committed_to_delivering}
                headingText={One_Product_at_a_Time}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={CrossImg}
                text={Our_diverse_team}
                headingText={Cross_Functional_Teams}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <CardApproach
                img={DataDriveImg}
                text={By_harnessing_the_power}
                headingText={Data_Driven_Decision}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default SectionApproach;
