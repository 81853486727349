import {
  Box,
  CardMedia,
  Container,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import {
  Global_Delivery_experience,
  Your_Reliable_Partner_in_Growth,
} from "../constants/translation";

function SectionTop({ img, title, subTitle }) {
  return (
    <Box margin={"auto"} pb={8}>
      <Hidden lgUp>
        <Box className="topSectionMobileView">
          <Box
            pt={{ xs: 4, md: 8, sm: 7 }}
            pb={{ xs: 8, sm: 7 }}
            className="topSectionMobileView2"
          >
            <Box marginTop={{ xs: 8, sm: 1 }} marginLeft={{ xs: 3 }}>
              <Container maxWidth="sm">
                <Typography variant="BigTitle">
                  {Your_Reliable_Partner_in_Growth}
                </Typography>
                <br />
                <Typography variant="SmallTitle">
                  {Global_Delivery_experience}
                </Typography>
              </Container>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden only={["md", "sm", "xs"]}>
        <Box className="topBackground">
          <Stack direction={"row"}>
            <Box
              margin={"auto"}
              pt={{ xs: 8, md: 8, sm: 7 }}
              pb={{ xs: 8, sm: 7, md: 4 }}
            >
              <Container maxWidth="sm">
                <Typography lineHeight={1.2} variant="BigTitle">
                  {title}
                </Typography>
                <Box pt={1}>
                  <Typography variant="SmallTitle">{subTitle}</Typography>
                </Box>
              </Container>
            </Box>
            <Box>
              <Box display={"flex"} sx={{ float: "right" }}>
                <Hidden lgDown>
                  <CardMedia
                    component="img"
                    className="landingPagImage"
                    src={img}
                    width={750}
                    height={492}
                  />
                </Hidden>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Hidden>
    </Box>
  );
}

export default SectionTop;
