import { Card, Hidden } from "@material-ui/core";
import { Box, CardContent, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { temp } from "../constants/Temp";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import "../style/servicesCard.scss";

const JobDescriptionCard = ({ title, responsibilities, Qualification }) => {
  return (
    <Box marginTop={2} width={800}>
      <Card>
        <CardContent>
          <Typography variant="subHeadingTitle">Job Description</Typography>
          <hr />
          <Box pt={1}>
            <Typography>{title}</Typography>
          </Box>
        </CardContent>
        <CardContent>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Typography variant="subHeadingTitle">
                Key Responsibilities
              </Typography>
              <li>{responsibilities}</li>
            </Box>
            <Box>
              <Typography variant="subHeadingTitle">Qualification</Typography>
              <Box>{Qualification}</Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

function SectionOpportunity() {
  const [sectionChange, setsectionChange] = useState(1);
  const OpportunityCard = ({ icon, Jobtitle }) => {
    return (
      <Card
        className="cardHover"
        sx={{ borderRadius: 10, backgroundColor: "#C2F0F9" }}
      >
        <Box padding={2} display={"flex"} justifyContent={"space-between"}>
          <Stack direction={"row"}>
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
              >
                <circle cx="35" cy="35" r="35" fill="#26CBE9" />
              </svg>
            </Box>
            <Box marginLeft={1} marginTop={2}>
              <Typography>{Jobtitle}</Typography>
            </Box>
          </Stack>
          <Box marginLeft={1} marginTop={2}>
            <ArrowCircleRightIcon fontSize="large" />
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Box justifyContent={"space-between"}>
      <Typography variant="headingTitle">Current Opportunities</Typography>
      <Stack direction={"row"}>
        <Box>
          {temp.map((item, key) => {
            return (
              <Box key={key} margin={2}>
                <div
                  onClick={() => {
                    setsectionChange(item.id);
                  }}
                >
                  <OpportunityCard Jobtitle={item.Job_title} />
                </div>
              </Box>
            );
          })}
        </Box>

        {temp.map((item, key) => {
          return (
            <Box key={key}>
              {sectionChange === item.id && (
                <JobDescriptionCard
                  title={item.Job_Description}
                  responsibilities={item.Key_Responsibilities}
                  Qualification={item.Qualification}
                />
              )}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}

export default SectionOpportunity;
