/** @format */

import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import '../style/focusCard.scss';
import { CardMedia } from '@material-ui/core';
import LogoImg from '../assets/Img/Logo.png';
import UsaFlag from '../assets/Img/united-states.png';
import IndFlag from '../assets/Img/flag.png';
import isoImg from '../assets/IsoImg.png';
import file from '../certificatefile/PRAVEETECH.pdf';
import {
  About,
  At_PraveeTech_excellence,
  Case_Studies,
  Explore,
  Home,
  Resources,
  Services,
} from '../constants/translation';
import { LinkedIn } from '@mui/icons-material';

function Footer({ handleService, handleAbout, handleCaseStudy, handleHome }) {
  const handelCertificateFile = () => {
    window.open(file);
  };

  return (
    <Box>
      <Box pt={{ xs: 5, sm: 15 }} pb={2} className='footerBackgroundImg'>
        <Container>
          <Box pb={5}>
            <Stack
              spacing={7}
              direction={{ xs: 'column', md: 'row', lg: 'row' }}
            >
              <Box alignItems={'start'}>
                <Card
                  sx={{
                    borderRadius: 3,
                    backgroundColor: 'white',
                    width: 193,
                    marginBottom: 2,
                  }}
                >
                  <CardMedia component='img' src={LogoImg} />
                </Card>
                <Typography color={'white'} variant='cardSubTitle'>
                  {At_PraveeTech_excellence}
                </Typography>
              </Box>
              <Stack
                spacing={{ xs: 5, sm: 2, md: 2, lg: 5 }}
                direction={{ sm: 'row' }}
              >
                <Box>
                  <Container>
                    <Box pb={3}>
                      <Typography color={'white'} variant='cardSubTitle'>
                        {Explore}
                      </Typography>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='117'
                          height='2'
                          viewBox='0 0 117 2'
                          fill='none'
                        >
                          <path
                            d='M0.277344 1.27539H116.455'
                            stroke='#26CBE9'
                          />
                        </svg>
                      </div>
                    </Box>
                    <Stack spacing={3}>
                      <div
                        style={{
                          cursor: 'pointer',
                          transition: 'color 0.3s',
                        }}
                        onClick={handleHome}
                      >
                        <Typography
                          onMouseEnter={(e) => {
                            e.target.style.borderBottom = '2px solid #10A5C1';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = 'white';
                            e.target.style.borderBottom = 'none';
                          }}
                          color={'white'}
                          variant='cardSubTitle'
                        >
                          {Home}
                        </Typography>
                      </div>
                      <div
                        style={{
                          cursor: 'pointer',
                          transition: 'color 0.3s',
                        }}
                        onClick={handleService}
                      >
                        <Typography
                          onMouseEnter={(e) => {
                            e.target.style.borderBottom = '2px solid #10A5C1';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = 'white';
                            e.target.style.borderBottom = 'none';
                          }}
                          color={'white'}
                          variant='cardSubTitle'
                        >
                          {Services}
                        </Typography>
                      </div>
                      <div
                        style={{
                          cursor: 'pointer',
                          transition: 'color 0.3s',
                        }}
                        onClick={handleAbout}
                      >
                        <Typography
                          onMouseEnter={(e) => {
                            e.target.style.borderBottom = '2px solid #10A5C1';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = 'white';
                            e.target.style.borderBottom = 'none';
                          }}
                          color={'white'}
                          variant='cardSubTitle'
                        >
                          {About}
                        </Typography>
                      </div>
                      <div
                        style={{
                          cursor: 'pointer',
                          transition: 'color 0.3s',
                          width: 120,
                        }}
                        onClick={handleCaseStudy}
                      >
                        <Typography
                          onMouseEnter={(e) => {
                            e.target.style.borderBottom = '2px solid #10A5C1';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = 'white';
                            e.target.style.borderBottom = 'none';
                          }}
                          color={'white'}
                          variant='cardSubTitle'
                        >
                          {Case_Studies}
                        </Typography>
                      </div>
                    </Stack>
                  </Container>
                </Box>

                <Box>
                  <Container>
                    <Box pb={3}>
                      <Typography color={'white'} variant='cardSubTitle'>
                        {Resources}
                      </Typography>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='117'
                          height='2'
                          viewBox='0 0 117 2'
                          fill='none'
                        >
                          <path
                            d='M0.277344 1.27539H116.455'
                            stroke='#26CBE9'
                          />
                        </svg>
                      </div>
                    </Box>
                    <Stack spacing={3}>
                      <div>
                        <Typography color={'white'} variant='cardSubTitle'>
                          Team
                        </Typography>
                      </div>
                      <div>
                        <Typography color={'white'} variant='cardSubTitle'>
                          Blog
                        </Typography>
                      </div>
                      <div>
                        <Typography color={'white'} variant='cardSubTitle'>
                          FAQ’s
                        </Typography>
                      </div>
                      <div>
                        <Typography color={'white'} variant='cardSubTitle'>
                          Gallery
                        </Typography>
                      </div>
                    </Stack>
                  </Container>
                </Box>

                <Box>
                  <Container>
                    <Box pb={3}>
                      <Typography color={'white'} variant='cardSubTitle'>
                        Address
                      </Typography>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='117'
                          height='2'
                          viewBox='0 0 117 2'
                          fill='none'
                        >
                          <path
                            d='M0.277344 1.27539H116.455'
                            stroke='#26CBE9'
                          />
                        </svg>
                      </div>
                    </Box>
                    <Box width={{ md: 210, lg: 285 }}>
                      <Box style={{ display: 'flex', gap: '10px' }}>
                        <div>
                          <img
                            src={IndFlag}
                            style={{
                              height: 'auto',
                              objectFit: 'contain',
                              width: '30px',
                              marginTop: '5px',
                            }}
                          />
                        </div>
                        <div>
                          <div>
                            <Typography color={'white'} variant='cardSubTitle'>
                              201,Chandravarsha commercial complex,Above SBI
                              Bank, Pashan - Sus Rd, Pashan, Pune, Maharashtra,
                              India - 411021
                            </Typography>
                          </div>
                        </div>
                      </Box>

                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          marginTop: '15px',
                        }}
                      >
                        <div>
                          <img
                            src={UsaFlag}
                            style={{
                              height: 'auto',
                              objectFit: 'contain',
                              width: '30px',
                              marginTop: '5px',
                            }}
                          />
                        </div>
                        <div>
                          <div>
                            <Typography color={'white'} variant='cardSubTitle'>
                              8401 memorial ln, Plano, Texas- USA - 75024
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Container>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Box pb={5}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: '45px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Typography color={'white'} variant='cardSubTitle'>
                      Follow us on
                    </Typography>
                  </div>
                  <div
                    onClick={() => {
                      window.open(
                        'https://www.linkedin.com/company/praveetechcorp/',
                        '_blank'
                      );
                    }}
                    style={{ position: 'relative', left: '30px' }}
                  >
                    <LinkedIn htmlColor='white' fontSize='large' />
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'relative', bottom: '30px', right: '70px' }}
                onClick={handelCertificateFile}
              >
                <img
                  src={isoImg}
                  style={{
                    height: 'auto',
                    objectFit: 'contain',
                    width: '170px',
                    marginTop: '5px',
                  }}
                />
              </div>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box pt={2} pb={{ xs: 6, sm: 5 }} sx={{ backgroundColor: '#26CBE9' }}>
        <Container maxWidth='lg'>
          <Box sx={{ float: 'right' }}>
            <Stack marginRight={{ xs: 6 }} spacing={1} direction={'row'}>
              <Box position={{ sm: 'relative' }} top={5}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 24 25'
                  fill='none'
                >
                  <g clip-path='url(#clip0_154_5380)'>
                    <path
                      d='M11.9998 24.9136C5.38347 24.9136 0 19.5328 0 12.9148C0 6.2994 5.38347 0.915926 11.9998 0.915926C18.6169 0.915926 24 6.2994 24 12.9148C24 19.5328 18.6169 24.9136 11.9998 24.9136ZM11.9998 3.58921C6.85691 3.58921 2.67335 7.77258 2.67335 12.9147C2.67335 18.0591 6.85691 22.2424 11.9998 22.2424C17.1432 22.2424 21.3268 18.059 21.3268 12.9147C21.3267 7.77258 17.1432 3.58921 11.9998 3.58921Z'
                      fill='#084955'
                    />
                    <path
                      d='M15.9391 10.7411C15.1726 9.42562 13.7471 8.60961 12.2192 8.60961C9.84651 8.60961 7.9138 10.5419 7.9138 12.9146C7.9138 15.2894 9.84651 17.2223 12.2192 17.2223C13.7472 17.2223 15.1726 16.4033 15.9391 15.0905L16.0382 14.9189H18.9302L18.7566 15.3845C18.2534 16.7177 17.3704 17.8513 16.2015 18.6603C15.0297 19.4715 13.6532 19.9019 12.2192 19.9019C8.36706 19.9019 5.23438 16.7665 5.23438 12.9146C5.23438 9.06255 8.36706 5.93012 12.2192 5.93012C13.6532 5.93012 15.0296 6.35902 16.2015 7.17144C17.3704 7.98072 18.2534 9.11388 18.7566 10.4471L18.9302 10.913H16.0382L15.9391 10.7411Z'
                      fill='#084955'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_154_5380'>
                      <rect
                        width='24'
                        height='24'
                        fill='white'
                        transform='matrix(1 0 0 -1 0 24.915)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <Box textAlign={'center'}>
                <Stack spacing={1} direction={'row'}>
                  <Typography variant='footerText'>PraveeTech</Typography>
                  <Typography variant='footerText'>
                    | All rights reserved
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
