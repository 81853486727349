import React from "react";
import img1 from "../assets/Img/story1.png";
import img2 from "../assets/Img/story2.png";
import img3 from "../assets/Img/OurStory.png";
import storyImg1 from "../assets/Img/yourStore/Image_1.png";
import storyImg2 from "../assets/Img/yourStore/Image_2.png";
import storyImg3 from "../assets/Img/yourStore/Image_3.png";
import { Box, Container, Hidden, Stack, Typography } from "@mui/material";
import {
  Discover_Our_Story,
  We_are_a,
  team_of_passionate_technologists,
  Global_Delivery_experience_across,
  Lead_by_seasoned,
  Expertise_in_various,
} from "../constants/translation";

function SectionStory() {
  const TableImg = () => {
    return (
      <Box marginLeft={{ sm: "auto" }}>
        <Box>
          <Stack
            padding={2}
            marginTop={3}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 6 }}
          >
            <img style={{ objectFit: "contain" }} height={285} src={img1}></img>

            <Box
              textAlign={{ xs: "center", sm: "start" }}
              paddingTop={{ sm: 5 }}
            >
              <Typography variant="subHeadingMobileTitle">
                {Discover_Our_Story}
              </Typography>
              <br />
              <Typography variant="headingMobileTitle">{We_are_a}</Typography>
              <br />
              <Typography variant="headingMobileTitle">
                {team_of_passionate_technologists}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box marginLeft={{ sm: 2 }} marginTop={5}>
          <Stack direction={{ xs: "column", sm: "row" }}>
            <img
              style={{ objectFit: "contain", height: 285 }}
              src={img2}
              alt="Image 2"
            />

            <Box
              marginTop={{ xs: 3, sm: 3 }}
              marginLeft={{ sm: 5 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width={{ xs: 500, sm: "auto" }}>
                <Stack spacing={2}>
                  <Stack spacing={2} direction="row">
                    <img
                      style={{
                        objectFit: "contain",
                        height: "57px",
                        width: "57px",
                      }}
                      src={storyImg1}
                      alt="Try Image"
                    />
                    <Box pt={1}>
                      <Typography variant="contentMobileTitle">
                        {Global_Delivery_experience_across}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack spacing={2} direction="row">
                    <img
                      style={{
                        objectFit: "contain",
                        height: "57px",
                        width: "57px",
                      }}
                      src={storyImg2}
                      alt="Try Image"
                    />
                    <Box pt={1}>
                      <Typography variant="contentMobileTitle">
                        {Lead_by_seasoned}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack spacing={2} direction="row">
                    <img
                      style={{
                        objectFit: "contain",
                        height: "57px",
                        width: "57px",
                      }}
                      src={storyImg3}
                      alt="Try Image"
                    />
                    <Box pt={1.2}>
                      <Typography variant="contentMobileTitle">
                        {Expertise_in_various}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  };

  const MediumScreen = () => {
    return (
      <Box marginTop={5}>
        <Box display={"flex"} justifyContent="center">
          <img src={img3} style={{ width: 515, height: 515 }} />
        </Box>

        <Container maxWidth="md">
          <Stack>
            <Box>
              <Box paddingTop={{ sm: 5 }}>
                <Typography variant="subHeadingTitle">
                  {Discover_Our_Story}
                </Typography>
                <br />
                <Typography variant="headingTitle">{We_are_a}</Typography>
                <br />
                <Typography variant="headingTitle">
                  {team_of_passionate_technologists}
                </Typography>
              </Box>
              <Box marginTop={{ xs: 3, sm: 3 }} display="flex">
                <Box>
                  <Stack spacing={2}>
                    <Stack spacing={2} direction="row">
                      {/* Replace this CardMedia */}
                      <img
                        src={storyImg1}
                        alt="Try Image"
                        style={{
                          objectFit: "contain",
                          height: "57px",
                          width: "57px",
                        }}
                      />
                      <Box pt={1} width={{ md: 500, lg: "auto" }}>
                        <Typography variant="contentTitle">
                          {Global_Delivery_experience_across}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack spacing={2} direction="row">
                      {/* Replace this CardMedia */}
                      <img
                        src={storyImg2}
                        alt="Try Image"
                        style={{
                          objectFit: "contain",
                          height: "57px",
                          width: "57px",
                        }}
                      />
                      <Box pt={1} width={{ md: 500, lg: "auto" }}>
                        <Typography variant="contentTitle">
                          {Lead_by_seasoned}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack spacing={2} direction="row">
                      {/* Replace this CardMedia */}
                      <img
                        src={storyImg3}
                        alt="Try Image"
                        style={{
                          objectFit: "contain",
                          height: "57px",
                          width: "57px",
                        }}
                      />
                      <Box pt={1} width={{ md: 500, lg: "auto" }}>
                        <Typography variant="contentTitle">
                          {Expertise_in_various}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Box>
    );
  };

  const LagerScreen = () => {
    return (
      <Box>
        <Stack
          spacing={{ md: 10, lg: 15 }}
          direction={{ md: "column", lg: "row" }}
        >
          <Box display={"flex"} justifyContent="start">
            <img src={img3} style={{ width: 515, height: 480 }} />
          </Box>

          <Box>
            <Container>
              <Stack>
                <Box>
                  <Box paddingTop={{ sm: 2 }}>
                    <Typography variant="subHeadingTitle">
                      {Discover_Our_Story}
                    </Typography>
                    <br />
                    <Typography variant="headingTitle">{We_are_a}</Typography>
                    <br />
                    <Typography variant="headingTitle">
                      {team_of_passionate_technologists}
                    </Typography>
                  </Box>
                  <Box marginTop={{ xs: 3, sm: 3 }} display="flex">
                    <Box>
                      <Stack spacing={2}>
                        <Stack spacing={2} direction="row">
                          <img
                            src={storyImg1}
                            alt="Try Image"
                            style={{
                              objectFit: "contain",
                              height: "57px",
                              width: "57px",
                            }}
                          />
                          <Box width={{ md: 500, lg: "auto" }}>
                            <Typography variant="contentTitle">
                              {Global_Delivery_experience_across}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <img
                            src={storyImg2}
                            alt="Try Image"
                            style={{
                              objectFit: "contain",
                              height: "57px",
                              width: "57px",
                            }}
                          />
                          <Box width={{ md: 500, lg: "auto" }}>
                            <Typography variant="contentTitle">
                              {Lead_by_seasoned}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <img
                            src={storyImg3}
                            alt="Try Image"
                            style={{
                              objectFit: "contain",
                              height: "57px",
                              width: "57px",
                            }}
                          />
                          <Box width={{ md: 500, lg: "auto" }}>
                            <Typography variant="contentTitle">
                              {Expertise_in_various}
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      alignContent="center"
      sx={{ backgroundColor: "#ebebeb", alignItems: "center" }}
      pt={{ xs: 2, sm: 2, md: 2, lg: 8 }}
      pb={{ xs: 5, sm: 2, md: 2, lg: 8 }}
    >
      <Container>
        <Stack>
          <Box marginLeft={{ sm: "auto", md: "none" }}>
            <Hidden mdUp>
              <TableImg />
            </Hidden>
            <Hidden lgDown>
              <LagerScreen />
            </Hidden>
          </Box>
        </Stack>
      </Container>
      <Hidden only={["xs", "lg", "xl", "sm"]}>
        <MediumScreen />
      </Hidden>
    </Box>
  );
}

export default SectionStory;
