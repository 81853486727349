import { Box, Card, Container, Hidden, Stack, Typography } from "@mui/material";
import React from "react";
import "../style/focusCard.scss";

import img1 from "../assets/Img/OurProcessImages/Design.png";
import img2 from "../assets/Img/OurProcessImages/Development1.png";
import img3 from "../assets/Img/OurProcessImages/Maintenance.png";
import img4 from "../assets/Img/OurProcessImages/Requirementgathering.png";
import img5 from "../assets/Img/OurProcessImages/TestingDeployment.png";
import {
  Creating_a_well_defined,
  Design,
  Development,
  Further_enhancements_according,
  Maintenance_Support,
  Our_expert_developers,
  Requirement_Gathering,
  Testing_Deployment,
  The_QA_team_tests,
  we_focus_on_selecting,
} from "../constants/translation";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function SectionProcess() {
  const [ref2, isInView2] = useInView({ triggerOnce: true });
  const ProcessCard = ({ img, headingText, subText }) => {
    return (
      <Box>
        <Container>
          <Stack direction={"row"}>
            <Hidden mdDown>
              <Box zIndex={1} position={"relative"} bottom={8} left={120}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="209"
                  height="210"
                  viewBox="0 0 209 210"
                  fill="none"
                >
                  <path
                    d="M187.099 41.2907C173.472 23.642 154.554 10.8179 133.113 4.69516C111.673 -1.42757 88.836 -0.527496 67.9441 7.26366C47.0521 15.0548 29.2022 29.328 17.0064 47.9945C4.81062 66.6611 -1.09071 88.7409 0.166009 111.003C1.42273 133.265 9.77152 154.54 23.9906 171.716C38.2097 188.891 57.5526 201.065 79.1887 206.455C100.825 211.845 123.618 210.17 144.233 201.673C164.848 193.176 182.203 178.304 193.757 159.234L159.049 138.205C151.984 149.865 141.373 158.958 128.769 164.153C116.164 169.348 102.228 170.373 88.9992 167.077C75.7704 163.781 63.9437 156.338 55.2498 145.837C46.556 135.335 41.4513 122.327 40.6829 108.716C39.9145 95.1042 43.5227 81.604 50.9795 70.1909C58.4363 58.7778 69.3502 50.0508 82.124 45.2871C94.8978 40.5234 108.861 39.9731 121.97 43.7166C135.079 47.4602 146.646 55.3012 154.978 66.092L187.099 41.2907Z"
                    fill="url(#paint0_linear_11_6866)"
                  />
                  <g filter="url(#filter0_d_11_6866)">
                    <circle
                      cx="104.364"
                      cy="105.316"
                      r="69.3642"
                      transform="rotate(-90 104.364 105.316)"
                      fill="white"
                    />
                    <image x="60" y="60" width="80" height="80" href={img} />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_11_6866"
                      x="35"
                      y="35.9522"
                      width="144.964"
                      height="144.963"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="3.11749" dy="3.11749" />
                      <feGaussianBlur stdDeviation="1.55875" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_11_6866"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_11_6866"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_11_6866"
                      x1="0"
                      y1="105.117"
                      x2="208.872"
                      y2="105.117"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1EA0B9" />
                      <stop offset="1" stop-color="#26CBE9" />
                    </linearGradient>
                  </defs>
                </svg>
              </Box>
            </Hidden>
            <Box>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: 10,
                  paddingTop: 3,
                  paddingBottom: 3,
                  backgroundColor: "white",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box height={{ md: 150 }}>
                  <Stack direction={"row"}>
                    <Hidden mdDown>
                      <Box position={"relative"} left={60} top={55}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="85"
                          height="10"
                          viewBox="0 0 85 10"
                          fill="none"
                        >
                          <path
                            d="M0.800293 5.1167L76.0098 5.1167"
                            stroke="#343434"
                            stroke-width="0.779373"
                            stroke-dasharray="1.56 1.56"
                          />
                          <circle
                            cx="80.2964"
                            cy="5.11673"
                            r="4.28655"
                            transform="rotate(-90 80.2964 5.11673)"
                            stroke="#343434"
                            stroke-width="0.779373"
                          />
                        </svg>
                      </Box>
                    </Hidden>
                    <Box paddingLeft={{ md: 8 }} paddingBottom={{ md: 5 }}>
                      <Container>
                        <Typography variant="ContactSubtile">
                          {headingText}
                        </Typography>
                        <br />
                        <Typography variant="cardSubTitle">
                          {subText}
                        </Typography>
                      </Container>
                    </Box>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Stack>
        </Container>
      </Box>
    );
  };

  const ProcessCardRight = ({ img, headingText, SubText }) => {
    return (
      <Box>
        <Container>
          <Stack direction={"row"}>
            <Box>
              <Card
                variant="outlined"
                sx={{
                  width: "100%",
                  borderRadius: 10,
                  paddingTop: 3,
                  paddingBottom: 3,
                  backgroundColor: "white",
                  alignItems: "center",
                }}
              >
                <Box height={{ md: 150 }}>
                  <Stack direction={"row"}>
                    <Box>
                      <Container>
                        <Typography variant="ContactSubtile">
                          {headingText}
                        </Typography>
                        <br />
                        <Box pr={5}>
                          <Typography variant="cardSubTitle">
                            {SubText}
                          </Typography>
                        </Box>
                      </Container>
                    </Box>

                    <Hidden mdDown>
                      <Box position={"relative"} right={65} top={50}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="85"
                          height="10"
                          viewBox="0 0 85 10"
                          fill="none"
                        >
                          <path
                            d="M84.1992 4.98877L8.98968 4.98877"
                            stroke="#343434"
                            stroke-width="0.779373"
                            stroke-dasharray="1.56 1.56"
                          />
                          <circle
                            cx="4.67624"
                            cy="4.67624"
                            r="4.28655"
                            transform="matrix(0 -1 -1 0 9.37891 9.66504)"
                            stroke="#343434"
                            stroke-width="0.779373"
                          />
                        </svg>
                      </Box>
                    </Hidden>
                  </Stack>
                </Box>
              </Card>
            </Box>
            <Hidden mdDown>
              <Box position={"relative"} right={120} bottom={10}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="209"
                  height="210"
                  viewBox="0 0 209 210"
                  fill="none"
                >
                  <path
                    d="M21.9012 41.1628C35.5283 23.5141 54.4462 10.69 75.8865 4.56723C97.3269 -1.5555 120.164 -0.655426 141.056 7.13573C161.948 14.9269 179.798 29.2001 191.994 47.8666C204.189 66.5331 210.091 88.613 208.834 110.875C207.577 133.137 199.228 154.412 185.009 171.588C170.79 188.763 151.447 200.937 129.811 206.327C108.175 211.717 85.3819 210.042 64.7669 201.545C44.1518 193.048 26.7974 178.176 15.2432 159.106L49.9512 138.077C57.0157 149.737 67.6266 158.83 80.2311 164.025C92.8356 169.221 106.772 170.245 120.001 166.949C133.23 163.654 145.056 156.21 153.75 145.709C162.444 135.207 167.549 122.199 168.317 108.588C169.085 94.9762 165.477 81.4761 158.02 70.063C150.564 58.6498 139.65 49.9229 126.876 45.1592C114.102 40.3955 100.139 39.8451 87.0299 43.5887C73.9208 47.3323 62.3539 55.1732 54.022 65.9641L21.9012 41.1628Z"
                    fill="url(#paint0_linear_11_6883)"
                  />
                  <g filter="url(#filter0_d_11_6883)">
                    <circle
                      cx="69.3642"
                      cy="69.3642"
                      r="69.3642"
                      transform="matrix(0 -1 -1 0 174 174.681)"
                      fill="white"
                    />
                    <image x="60" y="60" width="80" height="80" href={img} />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_11_6883"
                      x="29.034"
                      y="35.9521"
                      width="144.966"
                      height="144.966"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-3.12" dy="3.12" />
                      <feGaussianBlur stdDeviation="1.55875" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_11_6883"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_11_6883"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_11_6883"
                      x1="209"
                      y1="104.989"
                      x2="0.127945"
                      y2="104.989"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1EA0B9" />
                      <stop offset="1" stop-color="#26CBE9" />
                    </linearGradient>
                  </defs>
                </svg>
              </Box>
            </Hidden>
          </Stack>
        </Container>
      </Box>
    );
  };

  return (
    <Box mt={5}>
      <Container>
        <Box pb={10} textAlign={"center"}>
          <motion.div
            initial={{ opacity: 0, x: -150 }}
            animate={isInView2 ? { opacity: 1, x: 0 } : {}}
            transition={{ type: "spring", duration: 2 }}
            ref={ref2}
          >
            <Stack spacing={1}>
              <Typography variant="subHeadingTitle">Our Process</Typography>
              <Typography variant="headingTitle">We Follow Agile</Typography>
              <Typography variant="contentTitle2">
                Our Agile approach of project management prioritizes cross -
                functional
                <br />
                collaborations & continuous improvement
              </Typography>
            </Stack>
          </motion.div>
        </Box>
      </Container>

      <Box className="processImg">
        <Box paddingTop={10} paddingBottom={10}>
          <Stack spacing={5}>
            <ProcessCard
              img={img4}
              headingText={Requirement_Gathering}
              subText={Creating_a_well_defined}
            />
            <ProcessCardRight
              SubText={we_focus_on_selecting}
              headingText={Design}
              img={img1}
            />
            <ProcessCard
              subText={Our_expert_developers}
              headingText={Development}
              img={img2}
            />
            <ProcessCardRight
              SubText={The_QA_team_tests}
              headingText={Testing_Deployment}
              img={img5}
            />
            <ProcessCard
              subText={Further_enhancements_according}
              headingText={Maintenance_Support}
              img={img3}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionProcess;
