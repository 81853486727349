import React from "react";

function MarkSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M7.98896 1.39697C4.31407 1.39697 1.33154 4.38364 1.33154 8.06364C1.33154 11.7436 4.31407 14.7303 7.98896 14.7303C11.6639 14.7303 14.6464 11.7436 14.6464 8.06364C14.6464 4.38364 11.6639 1.39697 7.98896 1.39697ZM6.65748 11.397L3.32877 8.06364L4.26747 7.12364L6.65748 9.51031L11.7105 4.45031L12.6492 5.39697L6.65748 11.397Z"
        fill="#10A5C1"
      />
    </svg>
  );
}

export default MarkSvg;
