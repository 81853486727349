import {
  Box,
  CardMedia,
  Container,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import ourTeamImg1 from "../assets/Img/Rectangle39.png";
import teamImg2 from "../assets/Img/teamImg.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function SectionTeam() {
  const [ref2, isInView2] = useInView({ triggerOnce: true });

  return (
    <Box pt={15}>
      <CardMedia className="ourTeamImg">
        <Box pt={15} pb={15}>
          <Container>
            <Stack spacing={5} direction={{ xs: "column", md: "row" }}>
              <motion.div
                initial={{ opacity: 0, x: -150 }}
                animate={isInView2 ? { opacity: 1, x: 0 } : {}}
                transition={{ type: "spring", duration: 2 }}
                ref={ref2}
              >
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="subHeadingTitle">Our Team</Typography>

                    <Typography variant="headingTitle">
                      The Team Behind PraveeTech
                    </Typography>
                    <Typography variant="headingTitle2">
                      The Visionary minds behind PraveeTech leading the global
                      business ideas since 20+ years. Their commitment to
                      innovation & excellence drives our mission to deliver
                      solutions and trusted consultancy services in the
                      ever-evolving tech landscape
                    </Typography>
                  </Stack>
                </Box>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 150 }}
                animate={isInView2 ? { opacity: 1, x: 0 } : {}}
                transition={{ type: "spring", duration: 2 }}
                ref={ref2}
              >
                <Box>
                  <Stack
                    direction={{ xs: "column", sm: "row", md: "row" }}
                    spacing={{ xs: 5, sm: 10 }}
                  >
                    <Box textAlign={"center"}>
                      <Hidden mdDown>
                        <img src={ourTeamImg1} height={300} />
                      </Hidden>
                      <Hidden mdUp>
                        <CardMedia
                          sx={{ objectFit: "contain", resize: "both" }}
                          component={"img"}
                          src={ourTeamImg1}
                        />
                      </Hidden>
                      <Stack spacing={2} direction={"row"}>
                        <a
                          href="https://www.linkedin.com/in/prashant-wandile-15b1a26/"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <rect
                              y="0.901367"
                              width="23.9826"
                              height="23.19"
                              rx="11.595"
                              fill="#2680E9"
                            />
                            <path
                              d="M17.6127 6.11914H6.3697C6.11146 6.11914 5.86379 6.21834 5.68119 6.39491C5.49858 6.57148 5.396 6.81096 5.396 7.06067V17.9321C5.396 18.1818 5.49858 18.4213 5.68119 18.5979C5.86379 18.7744 6.11146 18.8736 6.3697 18.8736H17.6127C17.8709 18.8736 18.1186 18.7744 18.3012 18.5979C18.4838 18.4213 18.5864 18.1818 18.5864 17.9321V7.06067C18.5864 6.81096 18.4838 6.57148 18.3012 6.39491C18.1186 6.21834 17.8709 6.11914 17.6127 6.11914ZM9.32747 16.9844H7.34433V10.8932H9.32747V16.9844ZM8.33453 10.0491C8.10957 10.0479 7.89004 9.98226 7.70362 9.86051C7.51721 9.73875 7.37227 9.56633 7.2871 9.365C7.20194 9.16366 7.18035 8.94245 7.22508 8.72927C7.26981 8.51609 7.37885 8.3205 7.53842 8.16718C7.698 8.01387 7.90097 7.9097 8.12172 7.86781C8.34247 7.82593 8.5711 7.84822 8.77876 7.93187C8.98642 8.01551 9.1638 8.15676 9.28852 8.33779C9.41324 8.51882 9.4797 8.73152 9.47953 8.94905C9.48165 9.09468 9.45343 9.23923 9.39654 9.37409C9.33966 9.50895 9.25528 9.63136 9.14843 9.73401C9.04158 9.83667 8.91445 9.91747 8.77462 9.97161C8.63479 10.0257 8.48512 10.0521 8.33453 10.0491ZM16.6372 16.9897H14.6549V13.662C14.6549 12.6806 14.2235 12.3777 13.6666 12.3777C13.0785 12.3777 12.5014 12.8064 12.5014 13.6868V16.9897H10.5183V10.8976H12.4254V11.7417H12.451C12.6425 11.3671 13.313 10.7267 14.3362 10.7267C15.4427 10.7267 16.6381 11.3618 16.6381 13.2218L16.6372 16.9897Z"
                              fill="white"
                            />
                          </svg>
                        </a>

                        <Stack direction={"column"}>
                          <Typography variant="cardTitle">
                            Prashant Wandile
                          </Typography>
                          <Typography
                            textAlign={"start"}
                            sx={{ color: "#868686" }}
                            variant="cardSubTitle"
                          >
                            Co - Founder & CEO
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box textAlign={"center"}>
                      <Hidden mdDown>
                        <img src={teamImg2} height={300} />
                      </Hidden>
                      <Hidden mdUp>
                        <CardMedia
                          sx={{ objectFit: "contain", resize: "both" }}
                          component={"img"}
                          src={teamImg2}
                        />
                      </Hidden>
                      <Stack spacing={2} direction={"row"}>
                        <a
                          href="https://www.linkedin.com/in/shridhargune/"
                          target="_blank"
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <rect
                                y="0.901367"
                                width="23.9826"
                                height="23.19"
                                rx="11.595"
                                fill="#2680E9"
                              />
                              <path
                                d="M17.6127 6.11914H6.3697C6.11146 6.11914 5.86379 6.21834 5.68119 6.39491C5.49858 6.57148 5.396 6.81096 5.396 7.06067V17.9321C5.396 18.1818 5.49858 18.4213 5.68119 18.5979C5.86379 18.7744 6.11146 18.8736 6.3697 18.8736H17.6127C17.8709 18.8736 18.1186 18.7744 18.3012 18.5979C18.4838 18.4213 18.5864 18.1818 18.5864 17.9321V7.06067C18.5864 6.81096 18.4838 6.57148 18.3012 6.39491C18.1186 6.21834 17.8709 6.11914 17.6127 6.11914ZM9.32747 16.9844H7.34433V10.8932H9.32747V16.9844ZM8.33453 10.0491C8.10957 10.0479 7.89004 9.98226 7.70362 9.86051C7.51721 9.73875 7.37227 9.56633 7.2871 9.365C7.20194 9.16366 7.18035 8.94245 7.22508 8.72927C7.26981 8.51609 7.37885 8.3205 7.53842 8.16718C7.698 8.01387 7.90097 7.9097 8.12172 7.86781C8.34247 7.82593 8.5711 7.84822 8.77876 7.93187C8.98642 8.01551 9.1638 8.15676 9.28852 8.33779C9.41324 8.51882 9.4797 8.73152 9.47953 8.94905C9.48165 9.09468 9.45343 9.23923 9.39654 9.37409C9.33966 9.50895 9.25528 9.63136 9.14843 9.73401C9.04158 9.83667 8.91445 9.91747 8.77462 9.97161C8.63479 10.0257 8.48512 10.0521 8.33453 10.0491ZM16.6372 16.9897H14.6549V13.662C14.6549 12.6806 14.2235 12.3777 13.6666 12.3777C13.0785 12.3777 12.5014 12.8064 12.5014 13.6868V16.9897H10.5183V10.8976H12.4254V11.7417H12.451C12.6425 11.3671 13.313 10.7267 14.3362 10.7267C15.4427 10.7267 16.6381 11.3618 16.6381 13.2218L16.6372 16.9897Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </a>

                        <Stack direction={"column"}>
                          <Typography variant="cardTitle">
                            Shridhar Gune
                          </Typography>
                          <Typography
                            textAlign={"start"}
                            sx={{ color: "#868686" }}
                            variant="cardSubTitle"
                          >
                            Co - Founder & CTO
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </motion.div>
            </Stack>
          </Container>
        </Box>
      </CardMedia>
    </Box>
  );
}

export default SectionTeam;
