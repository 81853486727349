import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { router } from "./Router/Router";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme/light";
import ScrollTop from "./Router/ScrollTop";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollTop></ScrollTop>
        <Routes>
          {router.map((item) => {
            return <Route path={item.path} element={item.element} />;
          })}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
