import App from "../App";
import Career from "../container/Career";

export const router = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "career",
    element: <Career />,
  },
];
