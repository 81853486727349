export const temp = [
  {
    id: 1,
    Job_title: "HR Recruiter",
    Job_Description:
      "We are seeking a dynamic and experienced Business Analyst with 2-4 years of hands-on experience in the field. The ideal candidate will play a key role in bridging the gap between our clients' business needs and our technical solutions. As a Business Analyst, you will be responsible for gathering, analyzing, and documenting business requirements, as well as translating them into functional specifications. You will work closely with stakeholders and development teams to ensure successful project outcomes.",
    Key_Responsibilities:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
    Qualification:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
  },
  {
    id: 2,
    Job_title: ".Net Developer",
    Job_Description: "Hello .Net Developer ",
    Key_Responsibilities:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
    Qualification:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
  },
  {
    id: 3,
    Job_title: "Business Analyst",
    Job_Description:
      "We are seeking a dynamic and experienced Business Analyst with 2-4 years of hands-on experience in the field. The ideal candidate will play a key role in bridging the gap between our clients' business needs and our technical solutions. As a Business Analyst, you will be responsible for gathering, analyzing, and documenting business requirements, as well as translating them into functional specifications. You will work closely with stakeholders and development teams to ensure successful project outcomes.",
    Key_Responsibilities:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
    Qualification:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
  },
  {
    id: 4,
    Job_title: "DevOps Engineer",
    Job_Description:
      "We are seeking a dynamic and experienced Business Analyst with 2-4 years of hands-on experience in the field. The ideal candidate will play a key role in bridging the gap between our clients' business needs and our technical solutions. As a Business Analyst, you will be responsible for gathering, analyzing, and documenting business requirements, as well as translating them into functional specifications. You will work closely with stakeholders and development teams to ensure successful project outcomes.",
    Key_Responsibilities:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
    Qualification:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
  },
  {
    id: 5,
    Job_title: "IT Project Manager",
    Job_Description:
      "We are seeking a dynamic and experienced Business Analyst with 2-4 years of hands-on experience in the field. The ideal candidate will play a key role in bridging the gap between our clients' business needs and our technical solutions. As a Business Analyst, you will be responsible for gathering, analyzing, and documenting business requirements, as well as translating them into functional specifications. You will work closely with stakeholders and development teams to ensure successful project outcomes.",
    Key_Responsibilities:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
    Qualification:
      "Collaborate with clients and internal stakeholders to understand their business objectives and gather detailed requirements for projects. Document business requirements, functional specifications, and user stories clearly and comprehensively. Analyse data to identify trends, patterns, and insights that can inform business decisions and project strategies. Evaluate existing business processes, identify areas for improvement. Facilitate communication between business stakeholders and development teams.",
  },
];

export const IconJson = [{}];
