import React from "react";

function CardBackgroundLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="249"
      height="51"
      viewBox="0 0 249 51"
      fill="none"
    >
      <circle
        cx="131.375"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 131.375 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="3.65412"
        cy="47.2038"
        r="3.65412"
        transform="rotate(-90 3.65412 47.2038)"
        fill="#D8F9FF"
      />
      <circle
        cx="173.949"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 173.949 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="46.2274"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 46.2274 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="216.522"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 216.522 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="88.8016"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 88.8016 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="145.566"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 145.566 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="17.8453"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 17.8453 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="188.14"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 188.14 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="60.4188"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 60.4188 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="230.714"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 230.714 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="102.993"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 102.993 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="159.758"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 159.758 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="32.0369"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 32.0369 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="202.331"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 202.331 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="74.6102"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 74.6102 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="244.905"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 244.905 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="117.184"
        cy="47.204"
        r="3.65412"
        transform="rotate(-90 117.184 47.204)"
        fill="#D8F9FF"
      />
      <circle
        cx="131.375"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 131.375 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="3.65412"
        cy="33.0131"
        r="3.65412"
        transform="rotate(-90 3.65412 33.0131)"
        fill="#D8F9FF"
      />
      <circle
        cx="173.949"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 173.949 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="46.2274"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 46.2274 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="216.522"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 216.522 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="88.8016"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 88.8016 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="145.566"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 145.566 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="17.8453"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 17.8453 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="188.14"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 188.14 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="60.4188"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 60.4188 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="230.714"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 230.714 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="102.993"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 102.993 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="159.758"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 159.758 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="32.0369"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 32.0369 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="202.331"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 202.331 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="74.6102"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 74.6102 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="244.905"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 244.905 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="117.184"
        cy="33.0134"
        r="3.65412"
        transform="rotate(-90 117.184 33.0134)"
        fill="#D8F9FF"
      />
      <circle
        cx="131.375"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 131.375 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="3.65412"
        cy="18.822"
        r="3.65412"
        transform="rotate(-90 3.65412 18.822)"
        fill="#D8F9FF"
      />
      <circle
        cx="173.949"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 173.949 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="46.2274"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 46.2274 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="216.522"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 216.522 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="88.8016"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 88.8016 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="145.566"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 145.566 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="17.8453"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 17.8453 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="188.14"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 188.14 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="60.4188"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 60.4188 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="230.714"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 230.714 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="102.993"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 102.993 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="159.758"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 159.758 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="32.0369"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 32.0369 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="202.331"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 202.331 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="74.6102"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 74.6102 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="244.905"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 244.905 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="117.184"
        cy="18.8222"
        r="3.65412"
        transform="rotate(-90 117.184 18.8222)"
        fill="#D8F9FF"
      />
      <circle
        cx="131.375"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 131.375 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="3.65412"
        cy="4.63128"
        r="3.65412"
        transform="rotate(-90 3.65412 4.63128)"
        fill="#D8F9FF"
      />
      <circle
        cx="173.949"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 173.949 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="46.2274"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 46.2274 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="216.522"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 216.522 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="88.8016"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 88.8016 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="145.566"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 145.566 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="17.8453"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 17.8453 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="188.14"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 188.14 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="60.4188"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 60.4188 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="230.714"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 230.714 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="102.993"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 102.993 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="159.758"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 159.758 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="32.0369"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 32.0369 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="202.331"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 202.331 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="74.6102"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 74.6102 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="244.905"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 244.905 4.63152)"
        fill="#D8F9FF"
      />
      <circle
        cx="117.184"
        cy="4.63152"
        r="3.65412"
        transform="rotate(-90 117.184 4.63152)"
        fill="#D8F9FF"
      />
    </svg>
  );
}

export default CardBackgroundLeft;
