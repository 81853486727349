export const Your_Reliable_Partner_in_Growth =
  "PraveeTech - Your Reliable Partner in Growth";

export const Explore_the_limitless =
  "Explore the limitless opportunities to work with Praveetech";

export const Work_Environment = "Work Environment";
export const Work_Life_Balance = "Work-Life Balance";
export const Opportunities_for_Growth = "Opportunities for Growth";
export const Innovation_and_Technology = "Innovation and Technology";

export const We_maintain_positive =
  "We maintain positive and supportive work culture can significantly impact job satisfaction and supportive work culture can significantly impact job satisfaction";

export const Embark_on_a_journey =
  "Embark on a journey of endless possibilities with us, where innovation meets boundless opportunities. Join us to explore a career beyond limits and shape the future of technology.";
export const Global_Delivery_experience =
  " Global Delivery experience across various geographies and building complex IT systems. Expertise in various Business domains and Technologies.";
export const Discover_Our_Story = "Discover Our Story";
export const We_are_a = "We are a";
export const team_of_passionate_technologists =
  "team of passionate technologists";
export const Global_Delivery_experience_across =
  "  Global Delivery experience across various geographies & building complex IT systems";
export const Lead_by_seasoned =
  "Lead by seasoned IT professionals with 20+ years of experience";
export const Expertise_in_various =
  "Expertise in various Business domains and Technologies";
export const Our_Services = "Our Services";
export const Navigating_Cloud =
  " Navigating Cloud Excellence, Crafting Digital Solutions";
export const Our_expertise_in_crafting =
  "Our expertise in crafting tailored software solutions and ensuring their smooth operation is at the";
export const core_of_our_commitment =
  " core of our commitment to your success. Explore how our services can meet your unique business needs";
export const Leverage_cloud_computing =
  "Leverage cloud computing to achieve scalable and cost-effective infrastructure while harnessing the transformative potential of AI and advanced data analytics for data-driven decision-making";
export const Enhance_operational_efficiency =
  "Enhance operational efficiency by strategically automating repetitive tasks and workflows, liberating human resources for strategic initiatives through RPA solutions";
export const Technology_for_precise =
  "Technology for precise image & video analysis, facial recognition & object detection to optimize user experiences & automate visual-centric tasks across industries";
export const Instill_trust_and_security =
  "Instill trust and security in digital ecosystems with blockchain's decentralized ledger, ensuring transparency and risk mitigation in critical business processes and secure digital transactions";
export const Our_Excellance_in_data =
  "Our Excellance in data analytics empowers your business to make data-driven, strategic decisions, revealing actionable insights in today's data-centric business environment";
export const With_a_dedicated_focus =
  "With a dedicated focus on AR/VR, we deliver immersive experiences that redefine how businesses engage, train, and visualize, providing them with a distinct advantage in today's tech-driven landscape";
export const Cloud_Data_AI = "Cloud, Data & AI";
export const Robotic_Process_Automation = "Robotic Process Automation";
export const Computer_vision = "Computer vision";
export const Blockchain = "Blockchain";
export const Big_Data = "Big Data";
export const AR_VR = "AR / VR";
export const Our_Approach = "Our Approach";
export const Innovating_with_Purpose =
  " Innovating with Purpose, Delivering with Precision";
export const At_Praveetech_our_approach =
  "At Praveetech, our approach is rooted in a dynamic blend of innovation and precision. We harness cutting-edge technologies and a client-centric ethos to architect solutions that not only meet your immediate needs but also future-proof your business";
export const Agile_Methodology = "Agile Methodology";
export const Nimble_and_Adaptive = "Nimble and Adaptive";
export const Client_Centric_Approach = "Client-Centric Approach";
export const Cross_Functional_Teams = "Cross-Functional Teams";
export const Data_Driven_Decision = "Data-Driven Decision";
export const One_Product_at_a_Time = "One Product at a Time";
export const We_believe_in_the_power =
  "We believe in the power of Agile. Our development process is flexible, adaptive, and efficient. It allows us to respond swiftly to changes, ensuring that your projects are always on track ";
export const In_the_fast_paced =
  "In the fast-paced tech landscape, being nimble is key. We stay ahead of the curve, adopting the latest technologies and strategies to keep your business on the cutting edge";
export const Our_diverse_team =
  "Our diverse team seamlessly merges expertise from various domains, fostering a holistic approach that ensures well-rounded and comprehensive solutions";
export const By_harnessing_the_power =
  "By harnessing the power of data analytics, we drive decision-making precision, optimizing processes to deliver impactful & measurable results that truly matter to your business";
export const Committed_to_delivering =
  "We're committed to delivering quality over quantity. Focusing on one product at a time allows us to give your project the dedicated attention it deserves, ensuring exceptional results";
export const Your_are_at_the =
  " Your are at the heart of our operations. We tailor our services to your unique needs and objectives, ensuring that your vision becomes a reality";

export const Design = "Design";
export const Requirement_Gathering = "Requirement Gathering";
export const Development = "Development";
export const Testing_Deployment = "Testing & Deployment";
export const Maintenance_Support = "Maintenance & Support";
export const Creating_a_well_defined =
  "Creating a well defined scope of the project helps in ensuring timely delivery within the specified budget";
export const we_focus_on_selecting =
  "we focus on selecting the theme, color, flow, screen layout, features, and other aspects of the application. In a way it could be treated as a prototype which gives you the complete feel of the application without the functionality (without the code)";
export const Our_expert_developers =
  "Our expert developers work on the latest tech stack to provide you with a performance-driven custom web application development platform. The prototypes approved by the client are transformed into working code Following with Agile Process";
export const The_QA_team_tests =
  "The QA team tests the application before deployment. The designer, developer & QA members will check & test the complete functionality of the application to ensure that it runs smoothly when made live";
export const Further_enhancements_according =
  "Further enhancements according to the user's feedback, support and maintenance as well as new updates are equally necessary and hence this phase helps to improve client's service and stay ahead in the market";

export const Case_Study = "Case Study";
export const Stories_of_Success = " Stories of Success, Solutions in Action";
export const Explore_the_real_world =
  " Explore the real-world impact of our solutions through Praveetech's compelling case studies. Dive into stories of innovation, challenges overcome, and measurable results achieved.";

export const AV_AI = "AV/AI";
export const AffableBPM = "AffableBPM";
export const LIMS = "LIMS";
export const Medifit = "Medfitt";
export const US_based_AV = "US based AV/AI Firm";
export const AI_driven_voice =
  "AI-driven voice analyzer & mood analysis tool that provides insights from voice notes perfectly fits with recruiting.";
export const Technology_financials =
  "Technology & financials services platform providing touchless payments settlement of daily orders of pharmacies.";
export const An_End_to_End =
  "An End-to-End, Multiplatform SaaS solution for diagnostic center hosted on Microsoft Azure with 2000+ clients.";

export const AffableBPM_SaaS_solution =
  "AffableBPM SaaS solution eliminates manual tasks and optimizes end-to-end administrative business.";

export const OurTechnologies = "Our Technologies";
export const Advancement_across = "Advancement across a broad spectrum of ";
export const technologies = "technologies";
export const At_PraveeTech_we_stand =
  " At PraveeTech, we stand as pioneers in technology, driven by innovation and expertise. Our unwavering commitment to";
export const cutting_edge_solutions =
  " cutting-edge solutions propels businesses forward into a realm of limitless possibilities";
export const Our_Focus = "Our Focus";
export const Innovating_Tomorrow =
  " Innovating Tomorrow's Tech, We Are PraveeTech";
export const forward_into_a =
  " forward into a future defined by precision, intelligence,";
export const limitless_possibilities = "  and limitless possibilities";
export const Co_Founder = "Co - Founder & CEO";
export const Prashant_Wandile = "Prashant Wandile";
export const Shridhar_Gune = "Shridhar Gune";
export const At_PraveeTech_excellence =
  " At PraveeTech, we're passionate about technology and dedicated to delivering excellence. With a team of experts, we provide innovative IT solutions that drive your business forward. Trust us as your IT partner for strategic consulting and customized software development";
export const Home = " Home";
export const Services = "  Services";
export const About = "About";
export const Case_Studies = "Case Studies";
export const Explore = " Explore";
export const Resources = "Resources";
export const Contact_Now = " Contact Now";
export const Where_technology =
  "Where technology is not just our expertise; it's our passion";
export const Identifying_technology =
  "Identifying technology that align with Client’s software solution is paramount and we take care of the Technologies to support this growth and transformation.";
export const feedbackMsg =
  "PraveeTech has been an exceptional technology partner for our business. Their expertise in Microsoft technologies guided our seamless migration to Azure and the segments of modernization of AffableBPM solutions. Their team's dedication to customer success, setting up HIPAA compliance, and establishing our Azure marketplace presence is commendable. By emphasizing performance, reliability, and scalability in our solution and leveraging Microsoft Azure Fastrack, they significantly accelerated AffableBPM's time to market. Coupled with impeccable communication and a can-do attitude, PraveeTech stands as an invaluable ally in our tech journey.";
export const Harness_the_power =
  "Harness the power of  transformative tools to drive your business forward into a future defined by precision, intelligence, ";
