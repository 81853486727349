import React from "react";

function TwitterSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <rect
        x="0.662231"
        width="23.9748"
        height="24"
        rx="11.9874"
        fill="#1D9BF0"
      />
      <path
        d="M10.4559 18.3301C15.7724 18.3301 18.6793 13.9201 18.6793 10.0981C18.6793 9.97212 18.6793 9.84612 18.6733 9.72612C19.2367 9.31812 19.7282 8.80812 20.1178 8.22612C19.6023 8.45412 19.0449 8.61012 18.4575 8.68212C19.0569 8.32212 19.5124 7.75812 19.7282 7.08012C19.1708 7.41012 18.5534 7.65012 17.8941 7.78212C17.3667 7.21812 16.6175 6.87012 15.7843 6.87012C14.19 6.87012 12.8954 8.16612 12.8954 9.76212C12.8954 9.99012 12.9194 10.2121 12.9733 10.4221C10.5698 10.3021 8.44205 9.15012 7.01555 7.39812C6.76981 7.82412 6.62596 8.32212 6.62596 8.85012C6.62596 9.85212 7.13542 10.7401 7.91461 11.2561C7.4411 11.2441 6.99757 11.1121 6.60798 10.8961C6.60798 10.9081 6.60798 10.9201 6.60798 10.9321C6.60798 12.3361 7.60293 13.5001 8.92754 13.7701C8.68779 13.8361 8.43007 13.8721 8.16634 13.8721C7.98054 13.8721 7.80073 13.8541 7.62091 13.8181C7.98653 14.9701 9.05341 15.8041 10.3181 15.8281C9.32912 16.6021 8.08243 17.0641 6.72785 17.0641C6.4941 17.0641 6.26634 17.0521 6.03857 17.0221C7.30325 17.8501 8.82565 18.3301 10.4559 18.3301Z"
        fill="white"
      />
    </svg>
  );
}

export default TwitterSvg;
