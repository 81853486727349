import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Hidden,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import imgProfile from "../assets/Img/Feedback.png";
import imgmark from "../assets/Img/comma12.png";
import { AffableBPM, feedbackMsg } from "../constants/translation";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function SectionFeedBack() {
  const [ref, inView] = useInView({ triggerOnce: true });

  const FeedBackCard = () => {
    return (
      <Card
        sx={{
          color: "red",
          backgroundColor: "white",
          width: { md: 600 },
        }}
      >
        <Box pt={5}>
          <Container maxWidth="xlg">
            <Stack spacing={2} direction={"row"}>
              <Avatar sx={{ width: 84, height: 84 }} aria-label="recipe">
                <img width={84} height={84} src={imgProfile} />
              </Avatar>
              <Box textAlign={"justify"} position={"relative"} top={15}>
                <Stack>
                  <Typography fontSize={20} fontWeight={700} color={"#10A5C1"}>
                    Rajashree Varma
                  </Typography>
                  <Typography fontSize={14} fontWeight={500} color={"#989898"}>
                    Founder & CEO
                  </Typography>
                  <Typography fontSize={14} fontWeight={500} color={"#989898"}>
                    ({AffableBPM})
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <CardContent>
              <Typography
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#545454" }}
                textAlign={"justify"}
              >
                {feedbackMsg}
              </Typography>
            </CardContent>
            <CardContent>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Rating
                  sx={{ color: "#10A5C1" }}
                  name="read-only"
                  value={5}
                  readOnly
                />
                <img src={imgmark} />
              </Stack>
            </CardContent>
          </Container>
        </Box>
      </Card>
    );
  };

  const variants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 50,
    },
  };
  return (
    <Box pt={8}>
      <CardMedia className="ourFeedback">
        <motion.div
          animate={inView ? "visible" : "hidden"}
          variants={variants}
          transition={{ duration: 1, ease: "easeOut" }}
          ref={ref}
        >
          <Box textAlign={{ xs: "start", md: "center" }} pt={15} pb={10}>
            <Container>
              <Stack>
                <Typography color={" #0B0B0B"} variant="subHeadingTitle">
                  Our Feedback
                </Typography>
                <Box marginTop={1}>
                  <Typography variant="headingTitle">
                    Feedback Across The World
                  </Typography>
                </Box>
              </Stack>
              <Box marginTop={{ xs: 1 }}>
                <Typography variant="headingTitle2">
                  At PraveeTech, the voice of our clients is our most valuable
                  asset. We take immense pride in sharing the experiences and
                  <Hidden mdDown>
                    <br />
                  </Hidden>
                  opinions of those we've had the privilege to serve. Here,
                  you'll find genuine feedback from our valued clients, shedding
                  light
                  <Hidden mdDown>
                    <br />
                  </Hidden>
                  on their journeys and successes with Praveetech's solutions
                  and services
                </Typography>
              </Box>
            </Container>
          </Box>
        </motion.div>
        <Container>
          <Box display={"flex"} justifyContent={"space-around"} pb={10}>
            <Stack
              spacing={{ xs: 10, md: 2, lg: 10 }}
              direction={{ xs: "column", sm: "row" }}
            >
              <FeedBackCard />
            </Stack>
          </Box>
        </Container>
      </CardMedia>
    </Box>
  );
}

export default SectionFeedBack;
