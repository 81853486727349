import React from "react";

function FacebookSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clip-path="url(#clip0_154_5941)">
        <rect
          x="0.77533"
          width="23.9748"
          height="24"
          rx="11.9874"
          fill="#1877F2"
        />
        <path
          d="M17.429 15.4688L17.9605 12H14.6358V9.75C14.6358 8.80078 15.0994 7.875 16.5885 7.875H18.1009V4.92188C18.1009 4.92188 16.7289 4.6875 15.4178 4.6875C12.6785 4.6875 10.8898 6.34922 10.8898 9.35625V12H7.84607V15.4688H10.8898V23.8547C11.5008 23.9508 12.126 24 12.7628 24C13.3996 24 14.0247 23.9508 14.6358 23.8547V15.4688H17.429Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_154_5941">
          <rect
            x="0.77533"
            width="23.9748"
            height="24"
            rx="11.9874"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookSvg;
