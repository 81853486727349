import SectionStory from "../container/SectionStory";
import SectionFocus from "../container/SectionFocus";
import SectionTechnologies from "../container/SectionTechnologies";
import SectionApproach from "../container/SectionApproach";
import SectionProcess from "../container/SectionProcess";
import SectionTeam from "../container/SectionTeam";
import SectionCaseStudy from "../container/SectionCaseStudy";
import SectionFeedBack from "../container/SectionFeedBack";
import SectionTop from "../container/SectionTop";
import SectionContact from "../container/SectionContact";
import Footer from "../container/Footer";
import Navbar from "../components/Navbar/Navbar";
import SectionServices from "../container/SectionServices";
import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "../style/focusCard.scss";
import { useLocation, useNavigate } from "react-router";
import {
  Global_Delivery_experience,
  Your_Reliable_Partner_in_Growth,
} from "../constants/translation";
import mainImg from "../assets/Img/LandingPagImage.png";
import SectionAdvisor from "../container/SectionAdvisor";

function LandingPage() {
  const serviceRef = useRef(null);

  const aboutRef = useRef(null);
  console.log("aboutRef.current.offsetTop", aboutRef.offsetTop);
  const navigate = useNavigate();
  const mat = useLocation();
  console.log(" aboutRef", aboutRef);
  useEffect(() => {
    console.log("aboutRef.current", aboutRef.current);

    if (mat.state === "about" && aboutRef.current) {
      window.scrollTo({
        top: aboutRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [mat]);

  const caseStudyRef = useRef(null);
  const contactRef = useRef(null);
  const homeRef = useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleHome = () => {
    homeRef.current?.scrollIntoView({ behavior: "smooth" });
    setOpen(false);
  };

  const handleService = () => {
    serviceRef.current?.scrollIntoView({ behavior: "smooth" });
    setOpen(false);
  };

  const handleAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
    setOpen(false);
  };
  const handleCaseStudy = () => {
    caseStudyRef.current?.scrollIntoView({ behavior: "smooth" });
    setOpen(false);
  };
  const handleContact = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" });
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleCareerPage = () => {
    navigate("career");
  };

  return (
    <Box overflow={"hidden"}>
      <Box marginTop={{ sm: 4, md: 4 }}>
        <Navbar
          handleService={handleService}
          handleAbout={handleAbout}
          handleCaseStudy={handleCaseStudy}
          handleContact={handleContact}
          handleDrawerOpen={handleDrawerOpen}
          handleHome={handleHome}
          handleCareerPage={handleCareerPage}
          open={open}
          setOpen={setOpen}
        />
        <Box>
          <div ref={homeRef}>
            <SectionTop
              title={Your_Reliable_Partner_in_Growth}
              subTitle={Global_Delivery_experience}
              img={mainImg}
            />
          </div>
          <div>
            <SectionStory />
          </div>
          <div ref={serviceRef}>
            <SectionServices />
          </div>
          <div>
            <SectionFocus />
          </div>
          <div>
            <SectionTechnologies />
          </div>
          <div>
            <SectionApproach />
          </div>
          <div>
            <SectionProcess />
          </div>
          <div ref={caseStudyRef}>
            <SectionCaseStudy />
          </div>
          <div ref={aboutRef}>
            <SectionTeam />
          </div>
          <div>
            <SectionAdvisor />
          </div>
          <SectionFeedBack />
          <div ref={contactRef}>
            <SectionContact />
          </div>
        </Box>
      </Box>
      <Box>
        <Footer
          handleService={handleService}
          handleAbout={handleAbout}
          handleCaseStudy={handleCaseStudy}
          handleHome={handleHome}
          handleCareerPag={handleCareerPage}
        />
      </Box>
    </Box>
  );
}

export default LandingPage;
