import { createTheme } from "@mui/material";

let theme = createTheme({});

const themeTypography = {
  fontFamily: "Noto Sans, sans-serif",
  htmlFontSize: 16,
  fontSize: 16,
  fontWeight: 400,

  BigTitle: {
    fontFamily: " 'Noto Sans', sans-serif",
    fontSize: 54,
    fontWeight: 700,
    color: "white",
    [theme.breakpoints.only("xs")]: {
      fontSize: 24,
      fontWeight: 700,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 34,
      fontWeight: 700,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 34,
      fontWeight: 700,
    },
  },
  SmallTitle: {
    fontFamily: " 'Noto Sans', sans-serif",
    fontSize: 24,
    fontWeight: 500,
    color: "white",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  headingTitle: {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: "normal",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
      fontWeight: 700,
    },
    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  subHeadingTitle: {
    fontSize: 25,
    fontWeight: 700,
    lineHeight: "normal",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "normal",
    },
    color: "#10A5C1",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  subHeadingMobileTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "normal",
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
      fontWeight: 700,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 16,
      fontWeight: 700,
    },
    color: "#10A5C1",

    fontFamily: " 'Noto Sans', sans-serif",
  },
  headingMobileTitle: {
    fontSize: 34,
    fontWeight: 700,
    lineHeight: "normal",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
      fontWeight: 700,
    },
    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  contentMobileTitle: {
    fontSize: 14,
    fontWeight: 500,

    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },

  contentTitle: {
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.between("xs", "sm", "md")]: {
      fontSize: 14,
      fontWeight: 500,
    },

    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  contentTitle2: {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
      fontWeight: 500,
    },
    color: "#686868",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  cardTitle: {
    fontSize: 24,
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
      lineHeight: 1.5,
    },
    fontWeight: 600,
    lineHeight: "normal",
    color: "#181818",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  cardSubTitle: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.only("xs")]: {
      fontSize: 13,
      fontWeight: 500,
    },
    color: "#343434",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  SecondarySubTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 16,
      fontWeight: 500,
    },
    color: "#343434",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  SecondaryHeaderTitle: {
    fontSize: 20,
    fontWeight: 700,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
      fontWeight: 700,
    },
    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  caseStudySubTitle: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
      fontWeight: 600,
    },
    color: "white",
    fontFamily: " 'Noto Sans', sans-serif",
  },

  ContactSubtile: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
    [theme.breakpoints.between("xs", "sm", "md")]: {
      fontSize: 14,
      fontWeight: 600,
    },
    color: "#1A3C60",
    fontFamily: " 'Noto Sans', sans-serif",
  },

  footerText: {
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.only("xs")]: {
      fontSize: 12,
      fontWeight: 400,
    },
    color: "#084955",
    fontFamily: " 'Noto Sans', sans-serif",
  },
  footerSubText: {
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.only("xs")]: {
      fontSize: 10,
      fontWeight: 400,
    },
    color: "#084955",
    fontFamily: " 'Noto Sans', sans-serif",
  },
};

export default themeTypography;
