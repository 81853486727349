import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { Box, Container, Stack, Typography } from "@mui/material";
import SectionTop from "../container/SectionTop";
import { useNavigate } from "react-router";
import CareerCard from "../components/CareerCard/CareerCard";
import SectionOpportunity from "./SectionOpportunity";
import Footer from "./Footer";
import careerImg from "../assets/Img/CareerPageImg.png";

import {
  Embark_on_a_journey,
  Explore_the_limitless,
  Innovation_and_Technology,
  Opportunities_for_Growth,
  We_maintain_positive,
  Work_Environment,
  Work_Life_Balance,
} from "../constants/translation";

function Career() {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/", {});
  };

  const handleAbout = () => {
    navigate("/", { state: "about" });
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar handleHome={handleHome} handleAbout={handleAbout} />
      <Box marginTop={{ sm: 4, md: 4 }}>
        <SectionTop
          title={Explore_the_limitless}
          subTitle={Embark_on_a_journey}
          img={careerImg}
        />
      </Box>
      <Box pb={8} sx={{ backgroundColor: " #F9F9F9" }}>
        <Box pt={5}>
          <Container>
            <Typography variant="headingTitle">Why work with us</Typography>
            <Stack direction={"row"} spacing={2}>
              <CareerCard
                title={Work_Environment}
                subTitle={We_maintain_positive}
                number={"01"}
              />
              <CareerCard
                title={Work_Life_Balance}
                subTitle={We_maintain_positive}
                number={"02"}
              />
              <CareerCard
                title={Opportunities_for_Growth}
                subTitle={We_maintain_positive}
                number={"03"}
              />
              <CareerCard
                title={Innovation_and_Technology}
                subTitle={We_maintain_positive}
                number={"04"}
              />
            </Stack>
          </Container>
        </Box>
      </Box>
      <Box marginTop={8} pt={8} pb={8} sx={{ backgroundColor: "#F9F9F9" }}>
        <Container>
          <SectionOpportunity />
        </Container>
      </Box>
      <Box pt={8}>
        <Footer />
      </Box>
    </div>
  );
}

export default Career;
