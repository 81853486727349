/** @format */

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import PhoneImg from '../assets/Img/Phone.png';
import mailImg from '../assets/Img/Envelope.png';
import '../style/focusCard.scss';
import { Contact_Now } from '../constants/translation';
import UsaFlag from '../assets/Img/united-states.png';
import IndFlag from '../assets/Img/flag.png';

function SectionContact() {
  const handleClick = () => {
    const phoneNumber = '8956091722';
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Box pt={8} pb={8}>
      <Box className='contactBackgroundImg'>
        <Container>
          <Box pt={5} pb={8}>
            <Stack direction={{ sm: 'column', md: 'row' }}>
              <Box>
                <Stack>
                  <Container maxWidth='sm'>
                    <Typography variant='subHeadingTitle'>
                      Contact Us
                    </Typography>

                    <br />
                    <Box marginTop={1} marginBottom={1}>
                      <Typography variant='headingTitle'>
                        Let's Transform Your Business Together
                      </Typography>
                    </Box>

                    <Typography variant='headingTitle2'>
                      At PraveeTech, your feedback and inquiries are more than
                      just points of contact; they are the building blocks of
                      collaboration and innovation. We welcome the opportunity
                      to connect with you and are here to assist in any way we
                      can. Feel free to reach out to us.
                    </Typography>
                  </Container>
                </Stack>
              </Box>
              <Box margin={{ md: 'auto' }}>
                <Container maxWidth='sm'>
                  <Stack mt={5} spacing={3.1}>
                    <Box>
                      <Stack spacing={1} direction={'row'}>
                        <div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='37'
                            height='38'
                            viewBox='0 0 37 38'
                            fill='none'
                          >
                            <circle
                              cx='18.4615'
                              cy='18.7369'
                              r='18.4615'
                              fill='#1A3C60'
                            />
                            <image
                              x='6' // Adjust the x-coordinate as needed
                              y='6' // Adjust the y-coordinate as needed
                              width='25' // Adjust the width as needed
                              height='25' // Adjust the height as needed
                              href={mailImg}
                            />
                          </svg>
                        </div>
                        <Box>
                          <a
                            style={{ textDecoration: 'none' }}
                            href='mailto:sales@praveetech.com'
                          >
                            <Typography
                              position={{ xs: 'relative', sm: 'unset' }}
                              top={{ xs: 5 }}
                              variant='ContactSubtile'
                            >
                              sales@praveetech.com
                            </Typography>
                          </a>
                        </Box>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing={1} direction={'row'}>
                        <div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='37'
                            height='38'
                            viewBox='0 0 37 38'
                            fill='none'
                          >
                            <circle
                              cx='18.4615'
                              cy='18.7369'
                              r='18.4615'
                              fill='#1A3C60'
                            />
                            <image
                              x='6'
                              y='6'
                              width='25'
                              height='25'
                              href={PhoneImg}
                            />
                          </svg>
                        </div>
                        <Typography
                          position={{
                            xs: 'relative',
                            sm: 'unset',
                            md: 'unset',
                          }}
                          top={{ xs: 8 }}
                          variant='ContactSubtile'
                        >
                          +91 89560 91722
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{ marginTop: '10px' }}
                        spacing={1}
                        direction={'row'}
                      >
                        <div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='37'
                            height='38'
                            viewBox='0 0 37 38'
                            fill='none'
                          >
                            <circle
                              cx='18.4615'
                              cy='18.7369'
                              r='18.4615'
                              fill='#1A3C60'
                            />
                            <image
                              x='6'
                              y='6'
                              width='25'
                              height='25'
                              href={PhoneImg}
                            />
                          </svg>
                        </div>
                        <Typography
                          position={{
                            xs: 'relative',
                            sm: 'unset',
                            md: 'unset',
                          }}
                          top={{ xs: 8 }}
                          variant='ContactSubtile'
                        >
                          +1 (806)790-1540
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      <Button
                        sx={{ backgroundColor: '#1A3C60', borderRadius: 2 }}
                        variant='contained'
                        onClick={handleClick}
                      >
                        {Contact_Now}
                      </Button>
                    </Box>
                  </Stack>
                </Container>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SectionContact;
