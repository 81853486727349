import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MarkSvg from "../../assets/svg/MarkSvg";
import { Stack } from "@mui/system";
import "../../style/servicesCard.scss";

function ServiceCard({ svg, headerText, contendText }) {
  const serviceCardJson = {
    img: svg,
    header: headerText,
    mark: <MarkSvg />,
  };

  return (
    <Card
      sx={{
        backgroundColor: "white",
        borderRadius: "20px",
        boxShadow: " 30px #EBEBEB ",
        paddingTop: 5,
        paddingBottom: 6,
        maxWidth: 369,
        width: "100%",
      }}
    >
      <CardMedia
        sx={{ objectFit: "contain", resize: "both" }}
        component={"img"}
        height={220}
        src={serviceCardJson.img}
      />

      <CardContent>
        <Typography variant="cardTitle" component="div">
          {serviceCardJson.header}
        </Typography>

        {contendText.map((item) => {
          return (
            <Stack mt={2} direction={"row"} spacing={1.2}>
              <div>{item.marks}</div>
              <Typography variant="cardSubTitle">{item.contendText}</Typography>
            </Stack>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default ServiceCard;
