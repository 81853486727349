import { Box, Container, Hidden, Stack, Typography } from "@mui/material";
import React from "react";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import MarkSvg from "../assets/svg/MarkSvg";
import CardBackground from "../assets/svg/CardBackground";
import {
  Harness_the_power,
  Innovating_Tomorrow,
  Navigating_Cloud,
  Our_Focus,
  Our_Services,
  Our_expertise_in_crafting,
  core_of_our_commitment,
} from "../constants/translation";

import CustomAppImg from "../assets/Img/Technology/Customapp.png";
import ProductImg from "../assets/Img/Technology/ProductPlatform.png";
import TechnologyImg from "../assets/Img/Technology/Technologysolutioconsulting.png";
import CardBackgroundLeft from "../assets/svg/CardBackgroundLeft";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useInView } from "react-intersection-observer";

const contendTechnology = [
  {
    cardID: 1,
    marks: <MarkSvg />,
    contendText: " Business digitalization strategy ",
  },
  {
    cardID: 2,
    marks: <MarkSvg />,
    contendText: "Roadmap on new solution, technology implementation",
  },
  {
    cardID: 3,
    marks: <MarkSvg />,
    contendText:
      "Application Portfolio Rationalization & Strategic Application Review",
  },
];
const contendCustomApp = [
  {
    cardID: 1,
    marks: <MarkSvg />,
    contendText:
      " New applications - Developed from scratch, put into production & evolved further",
  },
  {
    cardID: 2,
    marks: <MarkSvg />,
    contendText: "Existing software that requires support & evolution",
  },
  {
    cardID: 3,
    marks: <MarkSvg />,
    contendText:
      "Covers all lifecycle phases starting from Business Analysis to Post-production support",
  },
];

const contendProductPlatform = [
  {
    cardID: 1,
    marks: <MarkSvg />,
    contendText:
      "Enabling the development of scalable & robust Product & Platforms ",
  },
  {
    cardID: 2,
    marks: <MarkSvg />,
    contendText: "Product & Platform Strategy",
  },
  {
    cardID: 3,
    marks: <MarkSvg />,
    contendText: "Product & Platform Engineering",
  },
  {
    cardID: 4,
    marks: <MarkSvg />,
    contendText: "Product Modernization",
  },
  {
    cardID: 5,
    marks: <MarkSvg />,
    contendText: "Product Sustenance & Support",
  },
];

function SectionServices() {
  const [ref, isInView] = useInView({ triggerOnce: true });
  const [ref2, isInView2] = useInView({ triggerOnce: true });

  return (
    <Box
      alignContent="center"
      sx={{ alignItems: "center" }}
      pt={{ xs: 8, sm: 5, md: 5, lg: 8 }}
      pb={{ xs: 5, sm: 5, md: 5, lg: 8 }}
    >
      <Container>
        <Stack direction={{ xs: "column", sm: "row" }}>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{ type: "spring", duration: 1.2 }}
          >
            <Stack>
              <Typography variant="subHeadingTitle">{Our_Services}</Typography>
              <br />
              <Typography variant="headingTitle">{Navigating_Cloud}</Typography>
              <br />
              <Typography variant="contentTitle2">
                {Our_expertise_in_crafting}
                <Hidden mdDown>
                  <br />
                </Hidden>
                {core_of_our_commitment}
              </Typography>
            </Stack>
          </motion.div>
        </Stack>
        <Hidden only={["xs", "lg", "xl"]}>
          <Box pt={5}>
            <Swiper
              navigation={true}
              style={{ height: 670 }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              centeredSlides
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ServiceCard
                  svg={TechnologyImg}
                  headerText={"Technology Solution Consulting"}
                  contendText={contendTechnology}
                />
              </SwiperSlide>

              <SwiperSlide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ServiceCard
                  svg={CustomAppImg}
                  headerText={"Custom App Development & Maintenance"}
                  contendText={contendCustomApp}
                />
              </SwiperSlide>
              <SwiperSlide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ServiceCard
                  svg={ProductImg}
                  headerText={"Product & Platform Development"}
                  contendText={contendProductPlatform}
                />
              </SwiperSlide>
            </Swiper>
          </Box>
        </Hidden>

        <Hidden only={["md", "sm"]}>
          <Box pt={5}>
            <Container>
              <Stack
                margin={"auto"}
                spacing={5}
                direction={{ xs: "column", sm: "row" }}
              >
                <ServiceCard
                  svg={TechnologyImg}
                  headerText={"Technology Solution Consulting"}
                  contendText={contendTechnology}
                />
                <ServiceCard
                  svg={CustomAppImg}
                  headerText={"Custom App Development & Maintenance"}
                  contendText={contendCustomApp}
                />

                <ServiceCard
                  svg={ProductImg}
                  headerText={"Product & Platform Development"}
                  contendText={contendProductPlatform}
                />
              </Stack>
            </Container>
          </Box>
        </Hidden>
      </Container>

      <Hidden lgDown>
        <Stack>
          <Box>
            <div
              style={{
                width: "100%",
                backgroundColor: "#26CBE9",
                height: 158,
                zIndex: -1,
                position: "relative",
                bottom: 420,
              }}
            >
              <Stack spacing={2}>
                <div
                  style={{
                    position: "absolute",
                    top: 85,
                    left: 0,
                  }}
                >
                  <CardBackground />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginLeft: "auto",
                  }}
                >
                  <CardBackgroundLeft />
                </div>
              </Stack>
            </div>
          </Box>
        </Stack>
      </Hidden>

      <Box
        pt={{ xs: 3, sm: 2, md: 2 }}
        pb={{ sm: 2, md: 2 }}
        textAlign={"center"}
      >
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={isInView2 ? { opacity: 1, x: 0 } : {}}
          transition={{ type: "spring", duration: 1.2 }}
          ref={ref2}
        >
          <Stack spacing={1}>
            <Typography variant="subHeadingTitle">{Our_Focus}</Typography>
            <Typography variant="headingTitle">
              {Innovating_Tomorrow}
            </Typography>
            <Typography variant="contentTitle2">
              {Harness_the_power}
              <Hidden lgDown>
                <br />
              </Hidden>
              and limitless possibilities
            </Typography>
          </Stack>
        </motion.div>
      </Box>
    </Box>
  );
}

export default SectionServices;
