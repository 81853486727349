import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import {
  AI_driven_voice,
  AffableBPM,
  AffableBPM_SaaS_solution,
  An_End_to_End,
  Case_Study,
  Explore_the_real_world,
  LIMS,
  Medifit,
  Stories_of_Success,
  Technology_financials,
  US_based_AV,
} from "../constants/translation";

import img1 from "../assets/Img/CaseStudy/AI.png";
import img2 from "../assets/Img/CaseStudy/AffableBPM.png";
import img3 from "../assets/Img/CaseStudy/LIMS.png";
import img4 from "../assets/Img/CaseStudy/Medifitt.png";
import imgHeader from "../assets/Img/CaseStudy/Affable.png";
import imgHeader1 from "../assets/Img/CaseStudy/Elab.png";
import imgHeader2 from "../assets/Img/CaseStudy/Group2.png";
import imgHeader3 from "../assets/Img/CaseStudy/Group3.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function SectionCaseStudy() {
  const [ref2, isInView2] = useInView({ triggerOnce: true });
  const CaseStudyCardImg = ({ headingImg, paddingValue }) => {
    return (
      <Card
        sx={{
          borderRadius: "2",
          backgroundColor: "white",
          width: 100,
        }}
      >
        <Box sx={{ padding: paddingValue }}>
          <CardMedia component="img" src={headingImg}></CardMedia>
        </Box>
      </Card>
    );
  };

  const CaseStudyCard = ({ img, subHeadingText, subText, headerImg }) => {
    const backgroundImageStyles = {
      backgroundImage: `url(${img})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 294,
      width: "auto",
    };

    return (
      <Box>
        <Card variant="outlined" sx={{ borderRadius: 3 }}>
          <Box sx={backgroundImageStyles}>
            <Box textAlign={"center"}>
              <Box pb={5} textAlign={"start"} pt={3}>
                {headerImg}
              </Box>
              <Box pb={3} textAlign={"start"}>
                <Stack w marginLeft={1.1} spacing={1}>
                  <Stack>
                    <Typography
                      sx={{ color: "white" }}
                      variant="subHeadingTitle"
                    >
                      {subHeadingText}
                    </Typography>
                  </Stack>
                  <Typography variant="caseStudySubTitle">{subText}</Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <Box textAlign={"center"} pt={{ xs: 12, sm: 8 }}>
      <motion.div
        initial={{ opacity: 0, x: 150 }}
        animate={isInView2 ? { opacity: 1, x: 0 } : {}}
        transition={{ type: "spring", duration: 2 }}
        ref={ref2}
      >
        <Box pb={8} textAlign={"center"}>
          <Container>
            <Typography variant="subHeadingTitle">{Case_Study}</Typography>
            <Typography variant="headingTitle">
              <br />
              {Stories_of_Success}
            </Typography>
            <Typography pt={2} paragraph variant="contentTitle2">
              {Explore_the_real_world}
            </Typography>
          </Container>
        </Box>
      </motion.div>
      <Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CaseStudyCard
                img={img1}
                subHeadingText={US_based_AV}
                subText={AI_driven_voice}
                headerImg={<CaseStudyCardImg headingImg={imgHeader2} />}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CaseStudyCard
                img={img4}
                headerImg={<CaseStudyCardImg headingImg={imgHeader} />}
                subHeadingText={AffableBPM}
                subText={AffableBPM_SaaS_solution}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CaseStudyCard
                img={img3}
                headerImg={
                  <CaseStudyCardImg
                    headingImg={imgHeader1}
                    paddingValue={1.8}
                  />
                }
                subHeadingText={LIMS}
                subText={An_End_to_End}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CaseStudyCard
                img={img2}
                headerImg={
                  <CaseStudyCardImg
                    headingImg={imgHeader3}
                    paddingValue={0.8}
                  />
                }
                subHeadingText={Medifit}
                subText={Technology_financials}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default SectionCaseStudy;
