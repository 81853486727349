import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import cardImg from "../assets/Img/OurFocus.png";
import {
  AR_VR,
  Big_Data,
  Blockchain,
  Cloud_Data_AI,
  Computer_vision,
  Enhance_operational_efficiency,
  Instill_trust_and_security,
  Leverage_cloud_computing,
  Our_Excellance_in_data,
  Robotic_Process_Automation,
  Technology_for_precise,
  With_a_dedicated_focus,
} from "../constants/translation";

import CloudImg from "../assets/Img/OurFocusImg/CloudDone.png";
import Robotic from "../assets/Img/OurFocusImg/Chatbot.png";
import Computervision from "../assets/Img/OurFocusImg/Workstation.png";
import BigData from "../assets/Img/OurFocusImg/Database.png";
import Ar_Vr from "../assets/Img/OurFocusImg/Virtual Reality.png";
import blockchain from "../assets/Img/OurFocusImg/Blockchain.png";

function SectionFocus() {
  const NextCard = ({ img, text, heading }) => {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            borderRadius: 10,
            boxShadow: "0px 25px 20px -30px rgba(0, 0, 0, 0.45)",
          }}
        >
          <Card
            sx={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
            }}
          >
            <Container maxWidth="md">
              <CardContent>
                <Stack spacing={1} direction={"row"}>
                  <Box>
                    <CardMedia component="img" src={img} />
                  </Box>
                  <Typography variant="SecondaryHeaderTitle">
                    {heading}
                  </Typography>
                </Stack>
                <Typography variant="cardSubTitle">{text}</Typography>
              </CardContent>
            </Container>
          </Card>
        </Box>
      </Container>
    );
  };

  return (
    <Box pt={5} pb={5} sx={{ backgroundColor: "#F9F9F9" }}>
      <Stack direction={{ xs: "row", sm: "row", md: "column", lg: "row" }}>
        <Box>
          <Hidden only={["sm", "xs"]}>
            <CardMedia className="cardBoard">
              <CardMedia className="borDerstyle">
                <Container>
                  <Stack
                    justifyContent={"center"}
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      position={"relative"}
                      justifyContent={"space-evenly"}
                    ></Stack>
                  </Stack>

                  <Box marginLeft={8} marginTop={{ sm: 10 }}>
                    <Hidden>
                      <img style={{ width: 400, height: 750 }} src={cardImg} />
                    </Hidden>
                  </Box>
                </Container>
              </CardMedia>
            </CardMedia>
          </Hidden>
        </Box>
        <Box>
          <Container maxWidth="md">
            <Stack pt={{ xs: 3, md: 3, lg: 8 }} spacing={3}>
              <NextCard
                img={CloudImg}
                heading={Cloud_Data_AI}
                text={Leverage_cloud_computing}
              />
              <NextCard
                img={Robotic}
                heading={Robotic_Process_Automation}
                text={Enhance_operational_efficiency}
              />
              <NextCard
                img={Computervision}
                heading={Computer_vision}
                text={Technology_for_precise}
              />
              <NextCard
                img={blockchain}
                heading={Blockchain}
                text={Instill_trust_and_security}
              />
              <NextCard
                img={BigData}
                heading={Big_Data}
                text={Our_Excellance_in_data}
              />
              <NextCard
                img={Ar_Vr}
                heading={AR_VR}
                text={With_a_dedicated_focus}
              />
            </Stack>
          </Container>
        </Box>
      </Stack>
    </Box>
  );
}

export default SectionFocus;
