import {
  Box,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../style/focusCard.scss";
import {
  Advancement_across,
  At_PraveeTech_we_stand,
  Identifying_technology,
  OurTechnologies,
  Where_technology,
  cutting_edge_solutions,
  technologies,
} from "../constants/translation";

import BackendImg from "../assets/Img/OurTechnologiesImages/Backend.png";
import CloudsImg from "../assets/Img/OurTechnologiesImages/Clouds.png";
import DatabaseImg from "../assets/Img/OurTechnologiesImages/Database.png";
import FrontendImg from "../assets/Img/OurTechnologiesImages/Frontend.png";
import MicrosoftSilverImg from "../assets/Img/OurTechnologiesImages/MicrosoftSilverPartner.png";
import mobileImg from "../assets/Img/OurTechnologiesImages/Mobile.png";
import PlatformsImg from "../assets/Img/OurTechnologiesImages/Platforms.png";
import TOGAFImg from "../assets/Img/OurTechnologiesImages/TOGAFLogo.png";
import ToolsImg from "../assets/Img/OurTechnologiesImages/Tools.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Hidden } from "@material-ui/core";

function SectionTechnologies() {
  const [ref2, isInView2] = useInView({ triggerOnce: true });
  return (
    <Box pt={8}>
      <Container>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={isInView2 ? { opacity: 1, x: 0 } : {}}
          transition={{ type: "spring", duration: 1.5 }}
          ref={ref2}
        >
          <Box>
            <Box marginBottom={1}>
              <Typography variant="subHeadingTitle">
                {OurTechnologies}
              </Typography>
            </Box>
            <Box marginBottom={1}>
              <Typography variant="headingTitle">
                {Advancement_across}
                <Hidden lgDown>
                  <br />
                </Hidden>
                {technologies}
              </Typography>
            </Box>
            <Box pb={8}>
              <Typography variant="contentTitle">
                {At_PraveeTech_we_stand}
                <Hidden lgDown>
                  <br />
                </Hidden>
                {cutting_edge_solutions}
              </Typography>
            </Box>
          </Box>
        </motion.div>
      </Container>
      <Box pt={8} pb={8} sx={{ backgroundColor: "#F9F9F9" }}>
        <Container>
          <Stack direction={{ sx: "column", md: "column", lg: "row" }}>
            <Box pb={8} margin={"auto"}>
              <Typography fontSize={32} variant="headingTitle">
                {Where_technology}
              </Typography>
              <br />
              <Box pt={1}>
                <Typography fontSize={16} variant="cardSubTitle">
                  {Identifying_technology}
                </Typography>
              </Box>
            </Box>

            <Box width={{ lg: "210%" }} marginLeft={{ lg: 20 }}>
              <Box className="technoBackgroundImg">
                <Grid
                  padding={5}
                  container
                  spacing={2}
                  rowSpacing={{ xs: 1, sm: 5, md: 3 }}
                  columnSpacing={{ xs: 1, sm: 5, md: 3 }}
                >
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      s
                      width={"auto"}
                      component="img"
                      src={FrontendImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={BackendImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={mobileImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={DatabaseImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={ToolsImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={CloudsImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <CardMedia
                      sx={{ objectFit: "contain" }}
                      height={"auto"}
                      width={"auto"}
                      component="img"
                      src={PlatformsImg}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3}>
                    <Stack alignItems={"center"} spacing={1}>
                      <Typography fontSize={18} variant="ContactSubtile">
                        Our Partner
                      </Typography>
                      <CardMedia
                        sx={{ objectFit: "contain" }}
                        height={"auto"}
                        width={"auto"}
                        component="img"
                        src={MicrosoftSilverImg}
                      />
                      <Typography fontSize={18} variant="ContactSubtile">
                        Certified with
                      </Typography>
                      <CardMedia
                        sx={{ objectFit: "contain" }}
                        height={"auto"}
                        width={"auto"}
                        component="img"
                        src={TOGAFImg}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default SectionTechnologies;
