import React from "react";

function CardBackground() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="230"
      height="51"
      viewBox="0 0 201 51"
      fill="none"
    >
      <circle
        cx="83.3753"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 83.3753 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="125.949"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 125.949 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="-1.77215"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 -1.77215 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="168.523"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 168.523 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="40.8016"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 40.8016 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="97.5662"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 97.5662 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="140.14"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 140.14 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="12.4193"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 12.4193 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="182.714"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 182.714 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="54.9925"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 54.9925 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="111.758"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 111.758 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="154.331"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 154.331 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="26.6102"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 26.6102 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="196.905"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 196.905 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="69.1839"
        cy="47.2043"
        r="3.65412"
        transform="rotate(-90 69.1839 47.2043)"
        fill="#D8F9FF"
      />
      <circle
        cx="83.3753"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 83.3753 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="125.949"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 125.949 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="-1.77215"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 -1.77215 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="168.523"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 168.523 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="40.8016"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 40.8016 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="97.5662"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 97.5662 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="140.14"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 140.14 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="12.4193"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 12.4193 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="182.714"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 182.714 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="54.9925"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 54.9925 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="111.758"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 111.758 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="154.331"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 154.331 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="26.6102"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 26.6102 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="196.905"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 196.905 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="69.1839"
        cy="33.0136"
        r="3.65412"
        transform="rotate(-90 69.1839 33.0136)"
        fill="#D8F9FF"
      />
      <circle
        cx="83.3753"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 83.3753 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="125.949"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 125.949 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="-1.77215"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 -1.77215 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="168.523"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 168.523 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="40.8016"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 40.8016 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="97.5662"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 97.5662 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="140.14"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 140.14 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="12.4193"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 12.4193 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="182.714"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 182.714 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="54.9925"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 54.9925 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="111.758"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 111.758 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="154.331"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 154.331 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="26.6102"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 26.6102 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="196.905"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 196.905 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="69.1839"
        cy="18.8224"
        r="3.65412"
        transform="rotate(-90 69.1839 18.8224)"
        fill="#D8F9FF"
      />
      <circle
        cx="83.3753"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 83.3753 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="125.949"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 125.949 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="-1.77215"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 -1.77215 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="168.523"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 168.523 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="40.8016"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 40.8016 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="97.5662"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 97.5662 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="140.14"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 140.14 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="12.4193"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 12.4193 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="182.714"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 182.714 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="54.9925"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 54.9925 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="111.758"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 111.758 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="154.331"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 154.331 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="26.6102"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 26.6102 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="196.905"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 196.905 4.63177)"
        fill="#D8F9FF"
      />
      <circle
        cx="69.1839"
        cy="4.63177"
        r="3.65412"
        transform="rotate(-90 69.1839 4.63177)"
        fill="#D8F9FF"
      />
    </svg>
  );
}

export default CardBackground;
