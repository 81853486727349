import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import StoryCircle from "../Circle/CircleLayout";
import React from "react";

function CareerCard({ title, subTitle, number }) {
  return (
    <Box>
      <Box>{/* <StoryCircle height={81} width={80} /> */}</Box>
      <Card
        sx={{
          borderRadius: 10,
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          maxHeight: "241px",
        }}
      >
        <Box display={"flex"}>
          <CardContent>
            <Box pt={2} pl={2} sx={{ width: 10 }}>
              <Box sx={{ transform: "rotate(-90deg)" }}>
                <Typography fontSize={23} variant="subHeadingMobileTitle">
                  {number}
                </Typography>
              </Box>
              <Box pl={1}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="42"
                  viewBox="0 0 2 42"
                  fill="none"
                >
                  <path
                    d="M1.07617 1.314L1.07617 40.4827"
                    stroke="#10A5C1"
                    stroke-width="1.72803"
                    stroke-linecap="round"
                  />
                </svg>
              </Box>
            </Box>
          </CardContent>
          <CardContent>
            <Box marginTop={2}>
              <Box>
                <Typography color={"black"}>{title}</Typography>
              </Box>
              <Box>
                <Typography variant="contentTitle">{subTitle}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}

export default CareerCard;
